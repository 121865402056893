/********************
 ACTIONS
 ********************/
 import { 
  CHECK_RESET, 
  CHECK_RESET_RESPONSE,
  RESET_PASSWORD,
  RESET_PASSWORD_RESPONSE
} from './types'

export const checkReset = (key) => ({
  type: CHECK_RESET,
  key
});

export const checkResetResponse = (status, user = null) => ({
  type: CHECK_RESET_RESPONSE,
  status,
  user
});

export const resetPassword = (user, key, data) => ({
  type: RESET_PASSWORD,
  user,
  key,
  data
})

export const resetPasswordResponse = (status) => ({
  type: RESET_PASSWORD_RESPONSE,
  status
})