import React from "react";

export default function Trio() {
  const trios = [
    {
      id: 1,
      title: "Premium quality",
      image: "/assets/images/quality.svg",
      description:
      "Markat kryesore të teknologjisë i gjeni në POINT Computers. Na vizitoni në dyqan ose online.",
    },
    {
      id: 2,
      title: "Transporti",
      image: "/assets/images/delivery.svg",
      description:
        "Transporti ēshtë falas kudo në Kosovë për të gjitha porositë. Transport i shpejtë dhe i sigurtë.",
    },
    {
      id: 3,
      title: "1 - 5 vite garancion",
      image: "/assets/images/guarantee.svg",
      description:
        "Të gjitha produktet në POINT Computers vijn me garanci, varësisht nga produkti nga 1 deri ne 5 Vite.",
    },
  ];

  return (
    <div className="trio">
      <div className="container">
        {trios &&
          trios.map((item) => (
            <div className="item" key={item.id}>
              <img src={item.image} alt={item.title} />
              <div className="info">
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
