/********************
 ACTION TYPES
 ********************/

export const GET_WISHLIST = "GET_WISHLIST";
export const ADD_PRODUCT_WISHLIST = "ADD_PRODUCT_WISHLIST";
export const REMOVE_PRODUCT_WISHLIST = "REMOVE_PRODUCT_WISHLIST";
export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const SET_WISHLIST_LENGTH = "SET_WISHLIST_LENGTH";
export const SET_WISHLIST_ADDED = "SET_WISHLIST_ADDED";
export const RESET_WISHLIST = "RESET_WISHLIST";

export const ADD_ALL_TO_CART = "ADD_ALL_TO_CART";