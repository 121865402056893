/********************
 ACTIONS
 ********************/
 import { 
  GET_FEATURED, 
  SET_FEATURED
} from './types'

export const getFeatured = (perPage, productType) => ({
  type: GET_FEATURED,
  productType,
  perPage
});

export const setFeatured = (featured) => ({
  type: SET_FEATURED,
  featured,
});