import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFeatured } from "../../redux/app/products/featured/actions";
import { fixPrice } from "../../utils/number";

export default function Featured() {
  const [active, setActive] = useState("newest");
  const { featured, isLoading } = useSelector((state) => state.featured);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatured(4, active));
  }, [active]);

  function scrolltoTop() {
    window.scrollTo(0, 0);
  }

  const Button = ({ title, count, value, active, setActive }) => {
    const handleClick = () => {
      setActive(value);
    };

    return (
      <button
        type="button"
        className={active === value ? "active" : null}
        onClick={handleClick}
      >
        {title}
        {/* <span>({ count })</span> */}
      </button>
    );
  };

  const Item = ({
    item,
    showPrice = false,
    showQty = false,
    onClick = null,
  }) => (
    <Link to={`/product/${item.product_id}`} className="item">
      <img src={item?.main_pair?.detailed?.image_path} alt={item?.product} />
      <h6>{item?.product}</h6>
      <div className="heading">
        <span
          className="price"
          style={{ color: "#EA1B25", paddingRight: "20px" }}
        >
          {item.price !== "0.000000"
            ? fixPrice(item?.price) + " \u20AC"
            : "Contact us for price"}
        </span>
        {item?.list_discount_prc > 0 && (
          <span
            className="old-price"
            style={{ textDecoration: "line-through", color: "#D6D6D6" }}
          >
            {fixPrice(item?.list_price)}&euro;
          </span>
        )}
      </div>
    </Link>
  );

  return (
    <div className={`featured-products ${isLoading && "loader"}`}>
      <div className="container">
        <div className="actions">
          <Button
            title="Më të kërkuarat"
            count={0}
            value="mostViewd"
            active={active}
            setActive={setActive}
          />
          <Button
            title="Më të rejat"
            count={0}
            value="mostSearched"
            active={active}
            setActive={setActive}
          />
          <Button
            title="Më të pëlqyerat"
            count={0}
            value="newest"
            active={active}
            setActive={setActive}
          />
        </div>
        <div className="products">
          {featured &&
            featured.map((item) => <Item key={item?.product_id} item={item} />)}
        </div>
      </div>
    </div>
  );
}
