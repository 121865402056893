import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import Button from "../components/Button";
import { Product } from "../components/Product";
import PreferedModels from "../components/Home/PreferedModels";
import Menu from "../components/Search/Menu";
import Loader from "../components/Loader";
import qs from "query-string";
import { useSelector, useDispatch } from "react-redux";
import {
  getSearch,
  getSearchCategory,
  getSearchFilters,
} from "../redux/app/search/actions";
import Pagination from "../components/Search/Pagination";
import MobileCategories from "../components/Search/MobileCategories";
import { useMediaQuery } from "react-responsive";
import { NotFoundProduct } from "../pages/404";

export default function Search(props) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [checked, setChecked] = useState([]);
  const [isOpened, setOpened] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const {
    products,
    categories,
    isLoading,
    isSearchLoading,
    filtersLoading,
    filters,
  } = useSelector((state) => state.search);
  const {
    page = 1,
    order,
    c,
    q,
    features_hash,
    items_per_page = 28,
  } = qs.parse(search);

  useEffect(() => {
    dispatch(getSearch(qs.parse(search)));
  }, [features_hash, page, order, q, items_per_page]);

  useEffect(() => {
    dispatch(getSearch(qs.parse(search), true));
    if (c) dispatch(getSearchCategory(c));
    dispatch(getSearchFilters(c));
  }, [c]);

  useEffect(() => {
    if (checked.length > 0) {
      let newArray = {};
      let string = "";
      checked.map((item) => {
        if (newArray[item.split("-")[0]])
          newArray[item.split("-")[0]] += "-" + item.split("-")[1];
        else newArray[item.split("-")[0]] = item.split("-")[1];
      });
      Object.keys(newArray).map(
        (item, key) =>
          (string += (key > 0 ? "_" : "") + item + "-" + newArray[item])
      );
      handleChangeMultiple({ features_hash: string, page: 1 });
    }
    // else if(features_hash && checked.length <= 0) {
    //   handleChangeMultiple({ features_hash: '', page: 1 });
    // }
  }, [checked]);

  useEffect(() => {
    if (features_hash) {
      let newArray = [];
      const features_array = features_hash.split("_");
      features_array.map((item) => {
        const subs = item.split("-");
        subs.slice(1).map((i) => newArray.push(subs[0] + "-" + i));
      });
      setChecked(newArray);
    } else {
      setChecked([]);
    }
  }, [features_hash]);

  const handleChange = (key, val) => {
    setSearchParams({ ...qs.parse(search), [key]: val });
  };

  const handleChangeMultiple = (data) => {
    setSearchParams({ ...qs.parse(search), ...data });
  };

  const handleFeatures = (value) => {
    if (checked.includes(value))
      setChecked((prevState) =>
        prevState.filter((prevItem) => prevItem !== value)
      );
    else setChecked((prevState) => [...prevState, value]);
    if (features_hash && checked.length <= 1)
      handleChangeMultiple({ features_hash: "", page: 1 });
  };

  const Selection = ({ prop, type, txt, active }) => (
    <span
      onClick={() => handleChangeMultiple({ [prop]: type, page: 1 })}
      className={active === type ? "active" : ""}
    >
      {txt}
    </span>
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumbs
        search={c && true}
        crumbs={c && categories}
        active_page={!c && "Search"}
      />

      <div className="container search-con">
        {c && isMobile && <MobileCategories c={c} isMobile={isMobile} />}
        {products.length == 0 && (
          <div className="d-flex align-center justify-between">
            <h3 className="page-title search-title">
              {q
                ? `Rezultatet per ${q}`
                : categories?.length > 0 &&
                  categories[categories?.length - 1].title}
            </h3>
          </div>
        )}

        {products.length > 1 && (
          <div className="d-flex align-center justify-between">
            <h3 className="page-title search-title">
              {q
                ? `Rezultatet per ${q}`
                : categories?.length > 0 &&
                  categories[categories?.length - 1].title}
            </h3>
            <h3 className="filters-mobile" onClick={() => setOpened(true)}>
              Filtrat
              <svg
                id="fi-rr-settings-sliders"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="9.242"
                viewBox="0 0 14 9.242"
              >
                <path
                  id="Path_579"
                  data-name="Path 579"
                  d="M.583,2.838h1.6a2.162,2.162,0,0,0,4.2,0h7.04a.6.6,0,0,0,0-1.2H6.376a2.162,2.162,0,0,0-4.2,0H.583a.6.6,0,0,0,0,1.2ZM4.278,1.2A1.034,1.034,0,0,1,5.3,2.241a1.021,1.021,0,1,1-2.042,0A1.035,1.035,0,0,1,4.278,1.2Z"
                  transform="translate(0 0)"
                  fill="#434343"
                />
                <path
                  id="Path_580"
                  data-name="Path 580"
                  d="M13.417,9.893h-1.6a2.161,2.161,0,0,0-4.2,0H.583a.6.6,0,0,0,0,1.2H7.624a2.161,2.161,0,0,0,4.2,0h1.6a.6.6,0,0,0,0-1.2ZM9.722,11.536a1.046,1.046,0,1,1,1.021-1.046A1.034,1.034,0,0,1,9.722,11.536Z"
                  transform="translate(0 -3.49)"
                  fill="#434343"
                />
              </svg>
            </h3>
            <div className="d-flex">
              <div className="dropdown">
                <div className="d-flex align-center order-by">
                  <span>Rendit sipas</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="4"
                    viewBox="0 0 8 4"
                    style={{ marginRight: "15px" }}
                  >
                    <path
                      id="fi-rr-angle-small-down"
                      d="M12.833,8.079a.581.581,0,0,0-.811,0L9.4,10.633a.581.581,0,0,1-.811,0L5.977,8.079a.581.581,0,0,0-.811,0,.548.548,0,0,0,0,.786l2.623,2.56a1.744,1.744,0,0,0,2.423,0l2.623-2.56A.548.548,0,0,0,12.833,8.079Z"
                      transform="translate(-4.999 -7.914)"
                      fill="#888"
                    />
                  </svg>
                </div>
                <div className="dropdown-items">
                  <Selection
                    prop="order"
                    type="popularity"
                    txt="Më të kërkuarat"
                    active={order}
                  />
                  <Selection
                    prop="order"
                    type="newest"
                    txt="Më të rejat"
                    active={order}
                  />
                  <Selection
                    prop="order"
                    type="highest"
                    txt="Cmimi: Lartë - Ultë"
                    active={order}
                  />
                  <Selection
                    prop="order"
                    type="lowest"
                    txt="Cmimi: Ultë - Lartë"
                    active={order}
                  />
                </div>
              </div>
              <div className="dropdown m-r-20">
                <div className="d-flex align-center order-by">
                  <span>{items_per_page} per faqe</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="4"
                    viewBox="0 0 8 4"
                  >
                    <path
                      id="fi-rr-angle-small-down"
                      d="M12.833,8.079a.581.581,0,0,0-.811,0L9.4,10.633a.581.581,0,0,1-.811,0L5.977,8.079a.581.581,0,0,0-.811,0,.548.548,0,0,0,0,.786l2.623,2.56a1.744,1.744,0,0,0,2.423,0l2.623-2.56A.548.548,0,0,0,12.833,8.079Z"
                      transform="translate(-4.999 -7.914)"
                      fill="#888"
                    />
                  </svg>
                </div>
                <div className="dropdown-items">
                  <Selection
                    prop="items_per_page"
                    type="44"
                    txt="44 per faqe"
                    active={items_per_page}
                  />
                  <Selection
                    prop="items_per_page"
                    type="60"
                    txt="60 per faqe"
                    active={items_per_page}
                  />
                  <Selection
                    prop="items_per_page"
                    type="84"
                    txt="84 per faqe"
                    active={items_per_page}
                  />
                  <Selection
                    prop="items_per_page"
                    type="112"
                    txt="112 per faqe"
                    active={items_per_page}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="search">
          {/* {products.length > 1 && ( */}
          <div className={`first ${isOpened && "show"}`}>
            <div className="filters-heading">
              <h3 className="title">Filterat</h3>
              <Button
                onClick={() => setOpened(false)}
                icon={
                  <svg
                    id="fi-rr-cross-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.421"
                    height="25.421"
                    viewBox="0 0 25.421 25.421"
                  >
                    <path
                      id="Path_420"
                      data-name="Path 420"
                      d="M16.491,8.017a1.059,1.059,0,0,0-1.5,0l-2.739,2.739L9.515,8.017a1.059,1.059,0,1,0-1.5,1.5l2.739,2.739L8.017,14.993a1.059,1.059,0,1,0,1.5,1.5l2.739-2.739,2.739,2.739a1.059,1.059,0,1,0,1.5-1.5l-2.739-2.739,2.739-2.739A1.059,1.059,0,0,0,16.491,8.017Z"
                      transform="translate(0.456 0.456)"
                      fill="#fff"
                    />
                    <path
                      id="Path_421"
                      data-name="Path 421"
                      d="M12.711,0A12.711,12.711,0,1,0,25.421,12.711,12.711,12.711,0,0,0,12.711,0Zm0,23.3A10.592,10.592,0,1,1,23.3,12.711,10.592,10.592,0,0,1,12.711,23.3Z"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </svg>
                }
              />
            </div>

            {products?.length > 0 && (
              <div
                className={`filters-container ${filtersLoading && "loader"}`}
              >
                <ul className="filters">
                  <p style={{ color: "#EA1B25" }}>Filtro sipas kategorise</p>
                  {filters &&
                    Object?.keys(filters).map((item) => {
                      return (
                        <Menu
                          key={item}
                          checked={checked}
                          item={filters[item]}
                          handleFeatures={handleFeatures}
                        />
                      );
                    })}
                </ul>
              </div>
            )}
            {/* {products.length > 0 && ( */}
            <div className="filters-footer">
              <Link
                to={`/search${c ? `?c=${c}` : ""}`}
                onClick={() => setOpened(false)}
                className="btn outline green"
              >
                Fshij Filtrat
              </Link>
              <Button
                classes="green block"
                title="Apliko"
                onClick={() => setOpened(false)}
              />
            </div>
            {/* )} */}
          </div>
          {/* )} */}
          {products.length < 1 && <NotFoundProduct />}

          {products.length > 0 && (
            <div className={`second ${isSearchLoading && "loader"}`}>
              {!isMobile && c && <MobileCategories c={c} isMobile={isMobile} />}
              <div className="all-products">
                {products &&
                  products.map((item) => (
                    <Product key={item.product_id} item={item} />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Pagination
        page={page}
        items_per_page={items_per_page}
        handleChange={handleChange}
      />

      <PreferedModels rows={1} heading="Trendet e fundit" />
    </>
  );
}
