import React from "react";
import { Field } from "formik";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { productInc, productDec } from "../redux/app/cart/actions";
import { useState } from "react";

export function Quantity({
  val = 1,
  min = 1,
  max = 10,
  setFieldValue,
  id = null,
}) {
  const name = !id ? "quantity" : `quantity[${id}]`;

  const inc = () => {
    // if (val >= max) return;
    setFieldValue(name, val + 1);
    checkIfThereAreProductsAvailable();
  };

  const dec = () => {
    // if (val <= min) return;
    if (val == min) {
      setReachedMax(false);
      setFieldValue(min);
    } else {
      setFieldValue(name, val - 1);
      setReachedMax(false);
    }
  };

  const [reachedMax, setReachedMax] = useState(false);

  const checkIfThereAreProductsAvailable = () => {
    if (val >= max) {
      setReachedMax(true);
      setFieldValue(name, max);
    }
  };

  return (
    <>
      <div className={`quantity`}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Button onClick={dec} title="-" />
        <Field type="number" name={name} />
        <Button onClick={inc} title="+" />
      </div>
      {reachedMax && (
        <p style={{ color: "red" }}>Nuk ka me shume se {max} ne stok.</p>
      )}
    </>
  );
}

export function Quantityx({ val = 1,
  min = 1,
  max = 10,
  name,
  setFieldValue,
  id = null, }) {
  const dispatch = useDispatch();
  const [amountAlert, setAmountAlert] = useState(false);

  const inc = () => {
    if (val == max) {
      checkIfThereAreProductsAvailable();
    }
    dispatch(productInc(id, 1));
  };

  const dec = () => {
    if (val <= min) return;
    dispatch(productDec(id, 1));
  };

  const handleChange = (e) => {
    if (val >= max || val <= min) return;
  };

  const checkIfThereAreProductsAvailable = () => {
    if (val >= max) {
      setAmountAlert(true);
      setFieldValue(name, max);
    }
  };

  return (
    <>
      {" "}
      <div className="qunatity-container">
        <div
          className={`quantity`}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button onClick={dec} title="-" />
          <input
            type="number"
            value={val}
            onChange={handleChange}
            name={"quantity"}
          // disabled="false"
          />
          <Button onClick={inc} title="+" />
        </div>
        {amountAlert && (
          <p style={{ color: "red", marginLeft: "12px" }}>Sasia e limituar</p>
        )}
      </div>
    </>
  );
}
