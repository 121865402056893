import {
    GET_SHIPPINGS,
    SET_SHIPPINGS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
    shippings: [],
    isLoading: false
};

export const shippingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SHIPPINGS:
            return {
                ...state,
                shippings: action.shippings,
                isLoading: true
            };
        case SET_SHIPPINGS:
            return {
                ...state,
                shippings: action.shippings,
                isLoading: false
            };
        default:
            return state
    }
};

export default shippingReducer;