import React from "react";

export function ErrorMessage({ type, message }) {
  return (
    <div className="error-message">
      <img src="/assets/images/error-icon.svg" alt="Error icon" />
      <span>{message}</span>
    </div>
  );
}
