import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import PreferedModels from "../components/Home/PreferedModels";
import { WishlistItem } from "../components/Product";
import { useSelector, useDispatch } from "react-redux";
import { removeProductFromWishlist } from "../redux/app/wishlist/actions";
import { addProductToCart } from "../redux/app/cart/actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmModal from "../components/ConfirmModal";

export default function Wishlist() {
  const { wishlistProducts } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            onClose={onClose}
            onRemove={() => dispatch(removeProductFromWishlist(id))}
          />
        );
      },
      overlayClassName: "confirmation-modal-overlay center",
    });
  };

  return (
    <>
      <Breadcrumbs active_page="Lista e deshirave" crumbs={[]} />

      <div className="container cart-container wishlist-container">
        <h3 className="page-title">Lista e deshirave</h3>
        <div className="all-products">
          <div className="container">
            {wishlistProducts &&
              wishlistProducts.map((item) => (
                <WishlistItem
                  key={item.product_id}
                  item={item}
                  addToCart={() =>
                    dispatch(
                      addProductToCart(item.product_id, 1, false, item.amount)
                    )
                  }
                  removeFromWishlist={() => handleRemove(item.product_id)}
                />
              ))}
          </div>
        </div>
        {(!wishlistProducts || wishlistProducts.length <= 0) && (
          <p>Lista e deshirave eshte e zbrazet.</p>
        )}
      </div>

      <PreferedModels rows={1} heading="Trendet e fundit" />
    </>
  );
}
