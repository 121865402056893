import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetOrder } from "../../redux/app/orders/actions";

export default function CartSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { created, order_id } = useSelector((state) => state.orders);

  useEffect(() => {
    if (!order_id || !created) navigate("/");
    return () => dispatch(resetOrder());
  }, [created, order_id]);

  return (
    <div className="cart-success">
      <img src="/assets/images/porosiaxxx.png" alt="Online shopping" />
      <div
        className="info"
        style={{ textAlign: "center", paddingLeft: "20px" }}
      >
        <h1 style={{ color: "#EA1B25" }}>Ju Faleminderit!</h1>
        <h2 style={{ fontSize: "28px", paddingTop: "20px" }}>
          Porosia #{order_id} është regjistruar
        </h2>
        <p>Informacionet e blerjes suaj mund ti gjeni ne email</p>
        <Link
          to="/"
          className="outline green"
          style={{
            border: "2px solid #EA1B25",
            borderRadius: "24px",
            padding: "10px 33px !important",
          }}
        >
          Kthehu në ballinë
        </Link>
      </div>
    </div>
  );
}
