/********************
 ACTIONS
 ********************/
 import { 
  SET_ALERT_MESSAGE
} from './types'

export const setMessage = (msgType, msg) => ({
  type: SET_ALERT_MESSAGE,
  msgType,
  msg
});