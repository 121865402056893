import React from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

export default function Pagination({ page, items_per_page, handleChange }) {
  const { params } = useSelector((state) => state.search);

  return (
    <div className="container">
      <div className="search" style={{ float: "right", marginBottom: "20px" }}>
        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.908"
                height="17.961"
                viewBox="0 0 8.908 17.961"
              >
                <path
                  id="Vector"
                  d="M0,15.84,6.52,9.32a1.986,1.986,0,0,0,0-2.8L0,0"
                  transform="translate(1.061 1.061)"
                  fill="none"
                  stroke="#292d32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            }
            onPageChange={(e) => handleChange("page", e.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={
              params?.total_items
                ? Math.ceil(params?.total_items / items_per_page)
                : 1
            }
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.908"
                height="17.961"
                viewBox="0 0 8.908 17.961"
              >
                <path
                  id="Vector"
                  d="M7.1,15.84.577,9.32a1.986,1.986,0,0,1,0-2.8L7.1,0"
                  transform="translate(0.75 1.061)"
                  fill="none"
                  stroke="#292d32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            }
            forcePage={page - 1}
            renderOnZeroPageCount={null}
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
}
