/********************
 ACTIONS
 ********************/
 import { 
  GET_WISHLIST,
  ADD_PRODUCT_WISHLIST,
  REMOVE_PRODUCT_WISHLIST,
  UPDATE_WISHLIST,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_ADDED,
  ADD_ALL_TO_CART,
  RESET_WISHLIST
} from './types'

export const getWishlist = () => ({
  type: GET_WISHLIST
});

export const resetWishlist = () => ({
  type: RESET_WISHLIST
});

export const addProductToWishlist = (productId, showMsg = true) => ({
  type: ADD_PRODUCT_WISHLIST,
  productId,
  showMsg
});

export const removeProductFromWishlist = (productId) => ({
  type: REMOVE_PRODUCT_WISHLIST,
  productId
});

export const updateWishlist = (product) => ({
  type: UPDATE_WISHLIST,
  product
});

export const setWishlistLength = (length) => ({
  type: SET_WISHLIST_LENGTH,
  length
});

export const setWishlistAdded = () => ({
  type: SET_WISHLIST_ADDED
});

export const addAllToCart = () => ({
  type: ADD_ALL_TO_CART
})