import { 
  GET_TRENDS_PRODUCTS,
  SET_TRENDS_PRODUCTS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isLoading: true,
  products: [],
  page: 1
};

export const trendsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRENDS_PRODUCTS:
      return {
        ...state,
        isLoading: true,
        page: action.data.page ? action.data.page : state.page
      }
    case SET_TRENDS_PRODUCTS:
      return {
        ...state,
        products: action.parent ? action.products : [...state.products, ...action.products],
        isLoading: false
      }

    default:
      return state
  }
};

export default trendsReducer;