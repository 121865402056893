/********************
 ACTIONS
 ********************/
import { 
  AUTHENTICATION_INIT, 
  AUTHENTICATION_LOGIN, 
  AUTHENTICATION_SUCCEED, 
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_FAILURE
} from './types'

export const init = () => ({
  type: AUTHENTICATION_INIT,
});

export const login = (email, password) => ({
  type: AUTHENTICATION_LOGIN,
  email,
  password
});

export const login_success = (token, user, alert = false) => ({
  type: AUTHENTICATION_SUCCEED,
  token,
  user,
  alert
});

export const logout = (msg = true) => ({
  type: AUTHENTICATION_LOGOUT,
  msg
});

export const failure = () => ({
  type: AUTHENTICATION_FAILURE
});