import { put, takeLatest } from "redux-saga/effects";
import { setDeals } from "./actions";
import api from "../../../utils/axios";

import { GET_DEALS } from "./types";

// watcher
export function* dealsWatcher() {
  yield takeLatest(GET_DEALS, INIT);
}

// worker
export function* INIT() {
  try {
    const data = (yield api.get("/categories/70/products", {
      params: {
        status: "A",
        items_per_page: 5,
        get_images: true,
        extend: ["promo_text"],
      },
    })).data;

    yield put(setDeals(data.products));
  } catch (e) {
    console.log(e);
  }
}
