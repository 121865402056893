/********************
 ACTIONS
 ********************/
 import { 
  GET_SEARCH,
  SET_SEARCH,
  GET_SEARCH_CATEGORIES,
  SET_SEARCH_CATEGORIES,
  GET_SEARCH_FILTERS,
  SET_SEARCH_FILTERS
} from './types'

export const getSearch = (data, parent = false) => ({
  type: GET_SEARCH,
  data,
  parent
});

export const setSearch = (products, params) => ({
  type: SET_SEARCH,
  products,
  params
});

export const getSearchCategory = (categoryId) => ({
  type: GET_SEARCH_CATEGORIES,
  categoryId
});

export const setSearchCategory = (category) => ({
  type: SET_SEARCH_CATEGORIES,
  category
});

export const getSearchFilters = (categoryId) => ({
  type: GET_SEARCH_FILTERS,
  categoryId
});

export const setSeachFilters = (filters) => ({
  type: SET_SEARCH_FILTERS,
  filters
});