import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function MobileCategories({ c, isMobile }) {
  const { allCategories } = useSelector((state) => state.categories);

  return (
    allCategories &&
    allCategories[c] && (
      <div className="categories-mobile">
        {isMobile && (
          <h3 className="active-category">
            <img
              src={allCategories[c].main_pair?.icon?.image_path}
              alt={allCategories[c].category}
            />
            {allCategories[c].category}
          </h3>
        )}
        {allCategories &&
          allCategories[c] &&
          (allCategories[c].level === 2 || isMobile) &&
          Object.keys(allCategories).filter(
            (item) => allCategories[item].parent_id === c
          ).length > 0 && (
            <div className={`sub-categories ${!isMobile && "smaller"}`}>
              {Object.keys(allCategories)
                .filter((item) => allCategories[item].parent_id === c)
                .map((item) => (
                  <Link
                    to={`?c=${allCategories[item].category_id}`}
                    className="item"
                    key={allCategories[item].category_id}
                  >
                    <img
                      src={allCategories[item].main_pair?.detailed?.image_path}
                      alt={allCategories[item].category}
                    />
                    <span>{allCategories[item].category}</span>
                  </Link>
                ))}
            </div>
          )}
      </div>
    )
  );
}
