import { put, takeLatest } from 'redux-saga/effects';
import { setBanners } from './actions'
import api from '../../../utils/axios'

import { 
  GET_BANNERS
} from './types'

// watcher
export function* bannersWatcher() {
  yield takeLatest(GET_BANNERS, INIT);
}

// worker
export function* INIT() {
  try {
    const blocks = (yield api.get('/4.0/sra_bm_blocks/70') || api.get('/4.0/sra_bm_blocks/120')).data;
    yield put(setBanners(blocks.content?.items))
  } catch (e){
    console.log(e);
  }
}