/********************
 ACTIONS
 ********************/
 import { 
  GET_BANNERS,
  SET_BANNERS
} from './types'

export const getBanners = () => ({
  type: GET_BANNERS
});

export const setBanners = (banners) => ({
  type: SET_BANNERS,
  banners
});