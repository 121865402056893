/********************
 ACTIONS
 ********************/
import { 
  GET_DEALS,
  SET_DEALS
} from './types'

export const getDeals = () => ({
  type: GET_DEALS
});

export const setDeals = (deals) => ({
  type: SET_DEALS,
  deals
});