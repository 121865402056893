import { REGISTER, REGISTER_SUCCESS, REGISTER_ERROR, RESET_REGISTER } from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isSubmitting: false,
  error: null,
  email: null
};

export const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        isSubmitting: true
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        email: action.email
      };
    case REGISTER_ERROR:
      return {
        ...state,
        isSubmitting: false,
        error: action.msg
      };
    case RESET_REGISTER:
      return {
        ...state,
        isSubmitting: false,
        email: null,
        error: null
      };
    default:
      return state
  }
};

export default registerReducer;