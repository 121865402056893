import { 
  CHANGE_PASSWORD,
  UPDATE_SUBMITTING,
  SET_USER,
  UPDATE_USER,
  REQUEST_RESET_PASSWORD,
  CLEAR_SUCCESS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isSubmitting: false,
  success: false,
  user: null
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        isSubmitting: true,
      };
    case UPDATE_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
        success: action.success
      };
    case UPDATE_USER:
      return {
        ...state,
        isSubmitting: true,
      };
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        isSubmitting: true,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
      };
    default:
      return state
  }
};

export default profileReducer;