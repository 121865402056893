import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export default function Footer() {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(300);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  function scrolltoTop() {
    window.scrollTo(0, 0);
  }

  return (
    <footer>
      <div className="container">
        <div>
          <div className="footer-about">
            <img src="/assets/images/pointLogoFooter.svg" alt="Buton logo" />
            <div className="socials">
              <a href="https://www.whatsapp.com/" target={"_blank"}>
                <img
                  src="/assets/images/icons/whatsapp.svg"
                  alt="Whatsapp logo"
                />
              </a>
              <a
                href="https://www.facebook.com/pointshopprishtine"
                target={"_blank"}
              >
                <img
                  src="/assets/images/icons/facebook.svg"
                  alt="Facebook logo"
                />
              </a>
              <a
                href="https://www.instagram.com/point_computers"
                target={"_blank"}
              >
                <img
                  src="/assets/images/icons/instagram.svg"
                  alt="Instagram logo"
                />
              </a>
            </div>
          </div>
          <ul>
            <li>
              <Link to="/costumer-care">Kujdesi ndaj klienteve</Link>
            </li>
            <li>
              <Link to="/costumer-care">Rreth Point Computers</Link>
            </li>
            <li>
              <Link to="/about-order">Pagesat</Link>
            </li>
            <li>
              <Link to="/transport">Transporti</Link>
            </li>
            <li>
              <Link to="/warranty-servis">Garancioni</Link>
            </li>
            <li>
              <Link to="/privacy">Politikat e Privatësisë</Link>
            </li>
            {/* <li>
              <Link to="/faqs">FAQs</Link>
            </li> */}
          </ul>
          <ul>
            <li>Kontakt</li>
            <li>
              <a href="tel:+383 49 111 320">+383 49 111 320</a>
            </li>
            <li>
              <a href="tel:+383 49 111 320">+383 49 111 320</a>
            </li>
            <li>
              <a href="mailto:info@point-computers.com">
                info@point-computers.com
              </a>
            </li>
            <li>
              Eqrem Qabej, Lokali Nr.76 10000, <br /> Prishtinë, Kosova
            </li>
          </ul>
          <ul>
            {/* <li><Link to='/dellwarranty'>Dell 5 vite garancion</Link></li>
            <li><Link to='/career'>Karriera</Link></li>
            <li><Link to='/terms'>Termet dhe kushtet</Link></li>
            <li><Link to='/returns'>Kthimet</Link></li> */}

            <li>
              <Link to="/profile">Llogaria </Link>
            </li>
            {!isAuthenticated && (
              <li>
                <Link to="/profile">Kyqu</Link>
              </li>
            )}
            {!isAuthenticated && (
              <li>
                <Link to="/register">Regjistrohu</Link>
              </li>
            )}
            {!isAuthenticated && (
              <li>
                <Link to="/reset">Keni harruar fjalëkalimin</Link>
              </li>
            )}

            {isAuthenticated && (
              <li>
                <Link to="/profile">Profili</Link>
              </li>
            )}
          </ul>
        </div>
        <div className="copyright">
          <p>© Copyright 2022 PointComputers. All Right Reserved</p>
        </div>
      </div>
      {show && (
        <div className="scrolltotop">
          <img
            src="/assets/images/icons/top-arrow.svg"
            alt=""
            onClick={() => scrolltoTop()}
          />
        </div>
      )}
    </footer>
  );
}
