import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDeals } from "../../redux/app/deals/actions";
import { getBanners } from "../../redux/app/banners/actions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fixPrice } from "../../utils/number";
import Loader from "../Loader";

export default function Banner() {
 const dispatch = useDispatch();
 const { deals, isLoading } = useSelector((state) => state.deals);
 const { banners } = useSelector((state) => state.banners);

 useEffect(() => {
  dispatch(getDeals());
  dispatch(getBanners());
  console.log(banners, "banners");
 }, [dispatch]);

 const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  // draggable: false,
  fade: true,
  rows: 1,
  initialSlide: 0,
  lazyLoad: true,
  arrows: false,
 };

 return (
  <>
   {isLoading && <Loader />}

   <div className="banner-container-home">
    <Slider {...settings} style={{ position: "relative" }}>
     {Array.isArray(banners)
      ? banners.map((item) => (
         <div style={{ position: "relative" }} key={item?.banner_id}>
          <a href={item.url} key={item.banner_id}>
           <img style={{ width: "100%", maxHeight: "900px" }} src={item?.main_pair?.icon?.image_path} alt=""></img>
          </a>
         </div>
        ))
      : null}
    </Slider>
   </div>
  </>
 );
}
