import React from "react";
import { fixPrice } from "../../utils/number";
import "./invoice.module.css";

export default function Invoice({ reff, order }) {
  return (
    <div ref={reff} style={{ padding: 30 }} className="print-font-family">
      <table>
        <tbody style={{ width: 100 }}>
          <tr>
            <td colSpan={2} style={{ paddingBottom: 40 }}>
              <img
                // src={`${process.env.REACT_APP_WEB_URL}/assets/images/logo-point.svg`}
                // src={"/assets/images/logo-point.svg"}
                src="/assets/images/logopoint.svg"
                alt="Point logo"
              />
            </td>
          </tr>
          <tr>
            <td style={{ borderBottom: "2px solid #f0f0f0", width: "50%" }}>
              <h3
                style={{ fontSize: 12, fontWeight: "bolder", paddingBottom: 8 }}
              >
                Detajet e porosisë:
              </h3>
              <p style={{ fontSize: 12, paddingBottom: 8 }}>
                Porosia: #{order.order_id}
              </p>
              <p style={{ fontSize: 12, paddingBottom: 8 }}>
                Data e porosisë:{" "}
                {new Date(order.timestamp * 1000).toLocaleString()}
              </p>
              <p style={{ fontSize: 12, paddingBottom: 8 }}>
                Menyra e pagesës: {order.payment_method.payment}
              </p>
              <p style={{ fontSize: 12, paddingBottom: 15, marginBottom: 25 }}>
                Menyra e transportit: {order.shipping[0].shipping}
              </p>
            </td>
            <td style={{ borderBottom: "2px solid #f0f0f0", width: "50%" }}>
              <h3
                style={{
                  fontSize: 12,
                  paddingBottom: 15,
                  fontWeight: "bolder",
                }}
              >
                Informacioni i klientit:
              </h3>
              <p style={{ fontSize: 12, paddingBottom: 8 }}>
                Emri dhe Mbiemri: {`${order.firstname} ${order.lastname}`}
              </p>
              <p style={{ fontSize: 12, paddingBottom: 8 }}>
                Adresa: {order.s_address}
              </p>
              <p style={{ fontSize: 12, paddingBottom: 8 }}>
                Qyteti: {order.s_city}
              </p>
              <p style={{ fontSize: 12, paddingBottom: 15, marginBottom: 25 }}>
                Numri i Telefonit: {order.b_phone}
              </p>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              {Object.keys(order.products).map((item) => {
                const product = order.products[item];
                return (
                  <div className="table-container" key={product.product_id}>
                    <div className="table-container-product">
                      <h3
                        style={{
                          fontSize: 12,
                          paddingBottom: 15,
                          fontWeight: "bolder",
                        }}
                      >
                        {" "}
                        Produkti{" "}
                      </h3>
                      <p> {product.product}</p>
                    </div>
                    <div className="tbl-all-actions">
                      <div className="tbl-price">
                        <h3
                          style={{
                            fontSize: 12,
                            paddingBottom: 15,
                            fontWeight: "bolder",
                          }}
                        >
                          {" "}
                          Cmimi{" "}
                        </h3>
                        <p> {fixPrice(product.price)} &euro; </p>
                      </div>
                      <div className="tbl-amount">
                        <h3
                          style={{
                            fontSize: 12,
                            paddingBottom: 15,
                            fontWeight: "bolder",
                          }}
                        >
                          {" "}
                          Sasia{" "}
                        </h3>
                        <p> {product.amount} </p>
                      </div>
                      <div className="tbl-total">
                        <h3
                          style={{
                            fontSize: 12,
                            paddingBottom: 15,
                            fontWeight: "bolder",
                          }}
                        >
                          {" "}
                          Totali{" "}
                        </h3>
                        <p> {fixPrice(product.subtotal)} &euro;</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </td>
          </tr>

          <tr>
            <td
              style={{
                width: "50%",
                paddingBottom: 20,
                paddingTop: 15,
                paddingRight: 50,
              }}
            >
              <h3
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  paddingBottom: 20,
                  borderBottom: "2px solid #f0f0f0",
                }}
              >
                CUSTOMER NOTES
              </h3>
              <p style={{ paddingTop: 20, fontSize: 12, lineHeight: "14.5px" }}>
                {order.notes}
              </p>
            </td>
            <td style={{ width: "50%" }}>
              <table>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: 13,
                        paddingBottom: 12,
                        paddingTop: 20,
                        fontWeight: "bolder",
                      }}
                    >
                      Nentotali
                    </td>
                    <td
                      style={{
                        fontSize: 14,
                        textAlign: "right",
                        paddingBottom: 12,
                        paddingTop: 20,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {fixPrice(order.subtotal)} &euro;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: 13,
                        paddingBottom: 12,
                        fontWeight: "bolder",
                      }}
                    >
                      Transporti
                    </td>
                    <td
                      style={{
                        fontSize: 14,
                        textAlign: "right",
                        paddingBottom: 12,
                        whiteSpace: "nowrap",
                      }}
                    >
                      0.00 &euro;
                    </td>
                  </tr>

                  {order.subtotal_discount ? (
                    <tr>
                      <td style={{ fontSize: 13, paddingBottom: 15 }}>
                        Kuponi
                      </td>
                      <td
                        style={{
                          fontSize: 14,
                          textAlign: "right",
                          paddingBottom: 15,
                          whiteSpace: "nowrap",
                        }}
                      >
                        -{fixPrice(order.subtotal_discount)} &euro;
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        borderTop: "2px solid #f0f0f0",
                        paddingTop: 15,
                        fontWeight: "bolder",
                      }}
                    >
                      Totali
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        paddingTop: 15,
                        fontWeight: "bolder",
                        fontSize: 16,
                        // color: "red",
                        borderTop: "2px solid #f0f0f0",
                      }}
                    >
                      {fixPrice(order.total)} &euro;
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                fontSize: 12,
                lineHeight: "14.5px",
                paddingTop: 30,
                textAlign: "center",
                color: "red",
              }}
            >
              Faleminderit që keni zgjedhur <b>PointComputers</b> © powered by{" "}
              <b>PC COMP sh.p.k</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
