import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Button from "../Button";
import { useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Categories({ isMobile }) {
  const slider = useRef();
  const { categories } = useSelector((state) => state.categories);

  var settings = {
    infinite: false,
    slidesToShow: 6,
    rows: 3,
    initialSlide: 0,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 4,
        },
      },
    ],
  };

  return (
    <div className="categories-slide container">
      {categories && (
        <Slider ref={slider} {...settings} className="slide-tracker-custom">
          {[...categories?.keys()].map((item) => {
            const category = categories?.get(item);
            return (
              <Link
                to={`/search?c=${category?.category_id}`}
                className="item"
                key={category?.category_id}
              >
                <div
                  className="inner"
                  style={{
                    backgroundImage: `url(${category?.main_pair?.detailed?.https_image_path})`
                  }}
                >
                  {/* <img src={ category?.main_pair?.detailed?.image_path } alt={ category.category } /> */}
                  <h5>{category?.category}</h5>
                </div>
              </Link>
            );
          })}
        </Slider>
      )}
    </div>
  );
}
