import { put, all, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { updateWishlist, setWishlistLength, setWishlistAdded } from './actions'
import { addProductToCart, getCart, addProductToCartNull } from '../cart/actions'
import { setMessage } from '../custom/actions'
import { 
  GET_WISHLIST,
  ADD_PRODUCT_WISHLIST,
  REMOVE_PRODUCT_WISHLIST,
  ADD_ALL_TO_CART
} from './types'
import api, { user_api } from '../../../utils/axios'
import { useSelector } from 'react-redux';


// watcher
export function* wishlistWatcher() {
  yield takeEvery(GET_WISHLIST, INIT);
  yield takeEvery(ADD_PRODUCT_WISHLIST, ADDPRODUCT);
  yield takeEvery(REMOVE_PRODUCT_WISHLIST, REMOVEPRODUCT);
  yield takeLatest(ADD_ALL_TO_CART, ADDALLTOCART);
}


// worker
export function* INIT() {
  try {
    let wishlist = JSON.parse(yield localStorage.getItem("wishlist"));
    if(wishlist) { 
      yield put(setWishlistLength(wishlist.length));
      for(let item of wishlist){
        yield call(ADDTOWISHLIST, item);
      }
    }
  } catch (e){
    console.log(e);
  }
}

function* ADDTOWISHLIST(productId) {
  const product = (yield api.get(`/products/${productId}`)).data;
  yield put(updateWishlist({
    product_id: productId,
    product: product.product,
    price: product.price,
    list_discount_prc: product.list_discount_prc,
    list_price: product.list_price,
    image: product.main_pair.detailed.image_path
  }))
}

function* ADDPRODUCT({ productId, showMsg }) {
  try {
    let wishlist = JSON.parse(yield localStorage.getItem("wishlist"));
    if(!wishlist) wishlist = [];

    const index = wishlist.findIndex(item => item === productId);
    if(index !== -1) {
      if(showMsg) yield put(setMessage('warning', 'Produkti tashme ekziston ne listen e deshirave.'));
      yield put(setWishlistAdded());
    } else {
      wishlist.push(productId);
      yield localStorage.setItem("wishlist", JSON.stringify(wishlist))
      yield put(setWishlistLength(wishlist.length));
      yield call(ADDTOWISHLIST, productId);
      if(showMsg) yield put(setMessage('success', 'Produkti u shtua ne listen e deshirave.'));
    }
  } catch (e){
    console.log(e);
  }
}

function* REMOVEPRODUCT({ productId }) {
  try {
    let wishlist = JSON.parse(yield localStorage.getItem("wishlist"));
    if(wishlist){
      const index = wishlist.findIndex(item => item === productId);
      if(index !== -1) {
        wishlist.splice(index, 1);
        yield put(setMessage('success', 'Produkti u fshi nga lista e deshirave.'));
        yield localStorage.setItem("wishlist", JSON.stringify(wishlist))
      }
    } 
  } catch (e){
    console.log(e);
  }
}

function* isAuth() {
  const isAuthenticated = yield select(state => state.auth.isAuthenticated)
  return isAuthenticated;
}

function* ADDALLTOCART() {
  try {
    let wishlist = JSON.parse(yield localStorage.getItem("wishlist"));
    if(wishlist){
      if(yield isAuth()){
        let products = {};
        wishlist.forEach(item => {
          products[item] = {
            product_id: item,
            amount: 1
          }
        })
        yield user_api.post(`/4.0/sra_cart_content`, { products });
        yield put(getCart())
      } else {
        yield all(wishlist.map(item => {
          return put(addProductToCart(item, 1, false, 1, false));
        
        }))
      }
      yield put(setMessage('info', 'Shporta juaj eshte perditesuar.'))
    }
  } catch (e){
    console.log(e);
  }
}




