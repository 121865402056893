import React, { Fragment, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Button from "../Button";
import InnerHTML from "dangerously-set-html-content";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
export default function Features() {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const { product, features, isInfoLoading } = useSelector(
    (state) => state.product
  );

  const html = product?.full_description || null;

  const handleShow = () => {
    if (show) {
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset - 180;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    setShow(!show);
  };

  return (
    <>
      <div
        className={`product-details ${isInfoLoading && "loader"} ${
          show && "show-all"
        }`}
        ref={ref}
      >
        <div className="first">
          <div className="">
            {/* <span className={`product-desc`}>Pershkrimi i produktit</span> */}
            <Tabs selectedTabClassName="active-tab">
              <TabList className="row" style={{ zIndex: "0", width:"100%" }}>
             
                  <Tab 
                  // className={`product-desc`} 
                  style={{ width: "50%", cursor: "pointer" }}>
                    Përshkrim i produktit
                  </Tab>
                  <Tab
                    // className={`product-desc details`}
                    style={{ width: "50%", cursor: "pointer" }}
                  >
                    Detajet
                  </Tab>
              </TabList>
              <TabPanel>
                {html ? (
                  <InnerHTML
                  style={{padding:"15px"}}
                    html={html}
                  />
                ) : (
                  <p style={{ padding: "15px" }}>
                    Përshkrimi është duke u përpunuar, ndërkohë mund të shikoni
                    specifikat teknike tek "Detajet" Nëse informacionet e
                    kerkuara nga ju nuk mund ti gjeni në uebfaqen e prodhuesit
                    ju mund të na kontaktoni për detaje të mëtutjeshme."
                  </p>
                )}
              </TabPanel>{" "}
              <TabPanel>
                {features &&
                  features.map((item, index) => (
                    <Fragment key={index}>
                      {index > 0 && (
                        <div className="row" key={item.feature_id}>
                          <p className="label heading">{item.description}</p>
                        </div>
                      )}
                      {item.subs.map((item2) => (
                        <div className="row" key={item2.feature_id}>
                          <p className="label">{item2.description}:</p>
                          <p className="value">
                            {item2.variant_id != 0
                              ? item2.variants[item2.variant_id].variant
                              : item2.value}
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  ))}

                {product?.features_description &&
                  Object.keys(product.features_description).map(
                    (item, index) => (
                      <Fragment key={index}>
                        <div className="row">
                          <p className="label heading">{item}</p>
                        </div>
                        {Object.keys(product.features_description[item]).map(
                          (item2, index2) => (
                            <div className="row" key={index2}>
                              <p className="label">{item2}:</p>
                              <p className="value">
                                {product.features_description[item][item2]}
                              </p>
                            </div>
                          )
                        )}
                      </Fragment>
                    )
                  )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-center ">
        <Button
          title={!show ? "Show more" : "Show less"}
          classes="btn outline blue show-more-features"
          onClick={handleShow}
        />
      </div> */}
    </>
  );
}
