/********************
 ACTION TYPES
 ********************/

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPDATE_SUBMITTING = "UPDATE_SUBMITTING";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";

export const CLEAR_SUCCESS = "CLEAR_SUCCESS";