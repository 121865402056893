/********************
 ACTIONS
 ********************/
import { REGISTER, REGISTER_SUCCESS, REGISTER_ERROR, RESET_REGISTER } from './types'

export const register = (user) => ({
  type: REGISTER,
  user
});

export const registerSuccess = (email) => ({
  type: REGISTER_SUCCESS,
  email
});

export const registerError = (msg) => ({
  type: REGISTER_ERROR,
  msg
});

export const resetRegister = () => ({
  type: RESET_REGISTER
});