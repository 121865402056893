import React, { Fragment } from "react";
import "../Orders/invoice.module.css";
import { fixPrice } from "../../utils/number";

export default function ProductPdf({ reff, product, features }) {
  return (
    <div ref={reff} className="print-font-family product-pdf">
      <div className="pdf-header">
        <a href="https://pointcomputers.bbros.al/">
          <img src="/assets/images/logopoint.svg" alt="Logo" />
        </a>
      </div>
      <div className="pdf-container">
        <div className="pdf-info">
          <div className="pdf-image">
            <img
              src={product?.main_pair.detailed.image_path}
              alt={product.product}
            />
          </div>
          <div className="pdf-desc">
            <div className="">
              <h3 className="pdf-title">{product.product}</h3>
              <p>
                <b>Kodi i produktit: </b>
                {product.product_code}
              </p>
              <p>
                <b>Koha e arritjes së produktit: </b> 4-9 ditë{" "}
              </p>
            </div>
            <div className="pdf-price">
              <span>Çmimi: </span>
              <h2 className="pdf-list-price">
                {product.price !== "0.000000"
                  ? fixPrice(product.price) + " \u20AC"
                  : "Contact us for price"}
              </h2>
              {product.list_discount_prc > 0 && (
                <h3 className="pdf-last-price">
                  {fixPrice(product.list_price)} &euro;
                </h3>
              )}
            </div>
          </div>
        </div>

        <table>
          <tbody>
            <tr>
              <td colSpan={2} className="pdf-table-heading">
                Përshkrimi i produktit
              </td>
            </tr>
            {features &&
              features.map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <tr key={item.feature_id}>
                      <td colSpan={2} className="pdf-label pdf-heading">
                        {item.description}
                      </td>
                    </tr>
                  )}
                  {item.subs.map((item2) => (
                    <tr key={item2.feature_id}>
                      <td className="pdf-label">{item2.description}:</td>
                      <td className="pdf-value">
                        {item2.variant_id != 0
                          ? item2.variants[item2.variant_id].variant
                          : item2.value}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            {product?.features_description &&
              Object.keys(product.features_description).map((item, index) => (
                <Fragment key={index}>
                  <tr>
                    <td colSpan={2} className="pdf-label pdf-heading">
                      {item}
                    </td>
                  </tr>
                  {Object.keys(product.features_description[item]).map(
                    (item2, index2) => (
                      <tr key={index2}>
                        <td className="pdf-label">{item2}:</td>
                        <td className="pdf-value">
                          {product.features_description[item][item2]}
                        </td>
                      </tr>
                    )
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <div className="pdf-footer" style={{ position: "static", marginTop: 50 }}>
        <p>
          Specifikat jan të bazuara në të dhënat e prodhuesit. <br />
          Point Computers nuk mban përgjegjësi nëse në specifikat e detajuara ka
          ndonjë gabim. <br />
          Për më tepër ju lutem të vizitoni webfaqen zyrtare të produktit.{" "}
          <br />
        </p>
        <div className="pdf-copyright">
          <p>
            &copy; Point Computers | Rr. Eqrem Qabej, Zona Industriale | Lokali
            Nr.76 10000 | +383 49 111 320
          </p>
        </div>
      </div>
    </div>
  );
}
