/********************
 ACTION TYPES
 ********************/

export const GET_ORDERS = "GET_ORDERS";
export const SET_ORDERS = "SET_ORDERS";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_RESPONSE = "CREATE_ORDER_RESPONSE";

export const RESET_ORDER = "RESET_ORDER";

export const SET_NOTES = "SET_NOTES";