import { put, takeLatest } from 'redux-saga/effects';
import { setMessage } from '../custom/actions'
import { CHECK_RESET, RESET_PASSWORD } from './types'
import { user_api } from '../../../utils/axios'
import { checkResetResponse, resetPasswordResponse } from './actions';

// watcher
export function* resetWatcher() {
  yield takeLatest(CHECK_RESET, CHECKRESET);
  yield takeLatest(RESET_PASSWORD, RESETPASSWORD);
}

// worker
export function* CHECKRESET({ key }) {
  try {
    const user = (yield user_api.get('/4.0/sra_profile', {
      headers: {
        "Authorization": 'Basic ' + btoa(key)
      }
    })).data;
    yield put(checkResetResponse(true, user));
  } catch (e){
    yield put(setMessage('warning', "Linku gabim apo ka skaduar."));
    yield put(checkResetResponse(false));
  }
}

export function* RESETPASSWORD({ user, key, data }) {
  try {
    yield user_api.put(`/4.0/reset_password/${user.user_id}`, {
      ...data,
      email: user.email
    }, {
      headers: {
        "Authorization": 'Basic ' + btoa(key)
      }
    });
    yield put(setMessage('success', "Fjalekalimi u perditesua me suksese."));
    yield put(resetPasswordResponse(true));
  } catch (e){
    yield put(resetPasswordResponse(false));
    yield put(setMessage('warning', e.response.data.message || "Kishte nje problem, provoni perseri."));
  }
}