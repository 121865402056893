import { 
  GET_SINGLE_PRODUCT,
  SET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_INFO,
  SET_SINGLE_PRODUCT_INFO,
  GET_SIMILAR_PRODUCTS,
  SET_SIMILAR_PRODUCTS,
  SET_PRODUCT_CRUMBS,
  SET_REVIEWS,
  ADD_REVIEW,
  ADD_REVIEW_RESPONSE
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isLoading: true,
  isInfoLoading: true,
  isSimilarProductsLoading: true,
  product: null,
  features: null,
  similarProducts: null,
  crumbs: [],
  reviews: 0,
  reviewLoading: false,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_PRODUCT:
      return {
        ...state,
        isLoading: true
      };
    case SET_SINGLE_PRODUCT:
      return {
        ...state,
        product: action.product,
        isLoading: false,
        crumbs: []
      };
      
    case GET_SINGLE_PRODUCT_INFO:
      return {
        ...state,
        isInfoLoading: true
      };
    case SET_SINGLE_PRODUCT_INFO:
      return {
        ...state,
        features: action.features,
        isInfoLoading: false
      };

    case GET_SIMILAR_PRODUCTS:
      return {
        ...state,
        isSimilarProductsLoading: true
      };
    case SET_SIMILAR_PRODUCTS:
      return {
        ...state,
        similarProducts: action.products,
        isSimilarProductsLoading: false
      };
    case SET_PRODUCT_CRUMBS:
      return {
        ...state,
        crumbs: [action.category, ...state.crumbs]
      };
    case ADD_REVIEW: 
      return {
        ...state,
        reviewLoading: true
      };
    case ADD_REVIEW_RESPONSE: 
      return {
        ...state,
        reviewLoading: false,
        reviews: action.status ? state.reviews + 1 : state.reviews
      };
    case SET_REVIEWS:
      return {
        ...state,
        reviews: action.count
      };
    default:
      return state
  }
};

export default productReducer;