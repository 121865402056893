import React from "react";
import { Product } from "../Product";

export default function ProductsSingle({
  products,
  children,
  isLoading,
  headingTitle = null,
}) {
  return (
    <div className={`all-products ${isLoading && "loader"}`}>
      {headingTitle && (
        <div className="section-heading">
          <h2 className="green">{headingTitle}</h2>
        </div>
      )}
      <div className="container">
        {products &&
          products.map((item) => <Product key={item.product_id} item={item} />)}
      </div>
      {children}
    </div>
  );
}
