export const sorting = (type) => {
  switch(type){
    case 'lowest':
      return { sort_by: 'price', sort_order: 'asc' }
    case 'highest':
      return { sort_by: 'price', sort_order: 'desc' }
    case 'popularity':
      return { sort_by: 'popularity', sort_order: 'desc' }
    case 'newest':
      return { sort_by: 'product_id', sort_order: 'desc' }
    default:
      return { sort_by: 'product_id', sort_order: 'desc' }
  }
}