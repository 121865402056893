import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBanners } from "../../redux/app/banners/actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Banners() {
 const dispatch = useDispatch();
 const { banners, isLoading } = useSelector((state) => state.banners);

 useEffect(() => {
  dispatch(getBanners());
 }, [dispatch]);

 var settings = {
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  rows: 1,
  lazyLoad: true,
 };

 return (
  <div className={`sale-banner container ${isLoading && "loader"}`}>
   {banners && banners.length > 0 && (
    <Slider {...settings}>
     {banners.map((item) => (
      <Link to={item.url} key={item.banner_id}>
       <img src={item?.main_pair?.icon?.image_path} alt="Banner pro" />
      </Link>
     ))}
    </Slider>
   )}
  </div>
 );
}
