import { GET_TRENDSHOME_PRODUCTS, SET_TRENDSHOME_PRODUCTS } from "./types";

/********************
   REDUCER
   ********************/

export const initialState = {
  isLoading: true,
  products: [],
  page: 1,
};

export const trendsHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRENDSHOME_PRODUCTS:
      return {
        ...state,
        isLoading: true,
        page: action.data.page ? action.data.page : state.page,
      };
    case SET_TRENDSHOME_PRODUCTS:
      return {
        ...state,
        products: action.parent
          ? action.products
          : [...state.products, ...action.products],
        isLoading: false,
      };

    default:
      return state;
  }
};

export default trendsHomeReducer;
