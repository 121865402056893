import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { removeProductFromCompare } from "../redux/app/compare/actions";
import { addProductToCart } from "../redux/app/cart/actions";
import { useSelector, useDispatch } from "react-redux";
import Button from "./Button";

const CompareTable = (props) => {
  const dispatch = useDispatch();
  const { added } = useSelector((state) => state.cart);

  function generateHeader(data) {
    return (
      <>
        <div className="row header">
          <p className="feature-value">Emri produktit</p>
          {data.map((product) => (
            <p className="feature-value">{product.name}</p>
          ))}
        </div>
        <div className="row body">
          <p className="feature-value">Foto</p>
          {data.map((product) => (
            <div className="feature-value">
              <Link to={`/product/${product.id}`} className=" product-image">
                <img src={product.image} />
              </Link>
            </div>
          ))}

          {data.length <= 3 && (
            <div className="feature-value add-compare">
              <Link to="/search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.822"
                  height="28.822"
                  viewBox="0 0 28.822 28.822"
                >
                  <g
                    id="Group_2"
                    data-name="Group 2"
                    transform="translate(28.822) rotate(90)"
                  >
                    <path
                      id="Path_420"
                      data-name="Path 420"
                      d="M9.959.352a1.2,1.2,0,0,0-1.7,0L5.156,3.457,2.05.352a1.2,1.2,0,1,0-1.7,1.7L3.457,5.155.352,8.261a1.2,1.2,0,1,0,1.7,1.7L5.156,6.854,8.261,9.959a1.2,1.2,0,1,0,1.7-1.7L6.854,5.155,9.959,2.05A1.2,1.2,0,0,0,9.959.352Z"
                      transform="translate(14.411 7.12) rotate(45)"
                      fill="#d8d8d8"
                    />
                    <path
                      id="Path_421"
                      data-name="Path 421"
                      d="M14.411,0A14.411,14.411,0,1,0,28.822,14.411,14.411,14.411,0,0,0,14.411,0Zm0,26.42A12.009,12.009,0,1,1,26.42,14.411,12.009,12.009,0,0,1,14.411,26.42Z"
                      transform="translate(0)"
                      fill="#d8d8d8"
                    />
                  </g>
                </svg>
                <span>Shto produkt</span>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }

  function generateBody(data) {
    return Object.keys(props.features).map((feature) =>
      productComparisonFeature(feature, props.features[feature], data)
    );
  }

  function productComparisonFeature(feature, featureName, data) {
    return (
      <div className="row body">
        <p className="feature-value">{featureName}</p>
        {data.map((product) => {
          return (
            <>
              {feature === "reviews" && (
                <div className="feature-value">
                  <Rating
                    readonly={true}
                    className="stars"
                    ratingValue={product[feature]}
                    emptyIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.904"
                        height="19.881"
                        viewBox="0 0 25.264 24.029"
                      >
                        <g id="stars" transform="translate(2.18 2.293)">
                          <path
                            id="Path"
                            d="M10.452,0l3.195,6.592,7.257,1L15.622,12.67l1.289,7.211-6.459-3.455-6.46,3.455,1.29-7.211L0,7.594l7.257-1Z"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#f8bf4c"
                            strokeWidth="2"
                          />
                        </g>
                      </svg>
                    }
                    fullIcon={
                      <svg
                        id="stars"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.904"
                        height="19.881"
                        viewBox="0 0 20.904 19.881"
                      >
                        <path
                          id="Path"
                          d="M10.452,0l3.195,6.592,7.257,1L15.622,12.67l1.289,7.211-6.459-3.455-6.46,3.455,1.29-7.211L0,7.594l7.257-1Z"
                          transform="translate(0 0)"
                          fill="#f8bf4c"
                        />
                      </svg>
                    }
                  />
                </div>
              )}
              {feature === "price" && (
                <p className="feature-value price">{product[feature]}&euro;</p>
              )}
              {feature !== "price" && feature !== "reviews" && (
                <p className="feature-value">
                  {product[feature] ? product[feature] : "/"}
                </p>
              )}
            </>
          );
        })}
      </div>
    );
  }

  function generateFooter(data) {
    props.setLoading(false);
    return (
      <div className="row footer">
        <p className="feature-value">&nbsp;</p>
        {data.map((item, i) => (
          <div className="feature-value">
            <Button
              classes="green block center"
              title="Shto ne shporte"
              loading={added == item.id}
              onClick={() =>
                dispatch(addProductToCart(item.id, 1, false, item.amount))
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.972"
                  height="17.972"
                  viewBox="0 0 17.972 17.972"
                >
                  <g
                    id="Group_367"
                    data-name="Group 367"
                    transform="translate(20288.973 -3060)"
                  >
                    <path
                      id="Rectangle_1561"
                      data-name="Rectangle 1561"
                      d="M1,0H15a1,1,0,0,1,1,1V9a2,2,0,0,1-2,2H2A2,2,0,0,1,0,9V1A1,1,0,0,1,1,0Z"
                      transform="translate(-20288 3066)"
                      fill="transparent"
                    />
                    <path
                      id="fi-rr-shopping-bag"
                      d="M15.726,4.493H13.479a4.493,4.493,0,1,0-8.986,0H2.247A2.247,2.247,0,0,0,0,6.74v7.488a3.749,3.749,0,0,0,3.744,3.744H14.228a3.749,3.749,0,0,0,3.744-3.744V6.74A2.247,2.247,0,0,0,15.726,4.493Zm-6.74-3a3,3,0,0,1,3,3H5.991A3,3,0,0,1,8.986,1.5Zm7.488,12.73a2.247,2.247,0,0,1-2.247,2.247H3.744A2.247,2.247,0,0,1,1.5,14.228V6.74a.749.749,0,0,1,.749-.749H4.493v1.5a.749.749,0,0,0,1.5,0v-1.5h5.991v1.5a.749.749,0,0,0,1.5,0v-1.5h2.247a.749.749,0,0,1,.749.749Z"
                      transform="translate(-20288.973 3060)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              }
            />
            <Button
              classes="outline blue center"
              onClick={() => dispatch(removeProductFromCompare(item.id))}
              icon={
                <svg
                  id="fi-rr-trash"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.724"
                  height="20.069"
                  viewBox="0 0 16.724 20.069"
                >
                  <path
                    id="Path_658"
                    data-name="Path 658"
                    d="M17.888,3.345H15.3A4.189,4.189,0,0,0,11.2,0H9.526a4.189,4.189,0,0,0-4.1,3.345H2.836a.836.836,0,1,0,0,1.672h.836V15.888a4.186,4.186,0,0,0,4.181,4.181h5.017a4.186,4.186,0,0,0,4.181-4.181V5.017h.836a.836.836,0,0,0,0-1.672ZM9.526,1.672H11.2a2.514,2.514,0,0,1,2.366,1.672H7.16A2.514,2.514,0,0,1,9.526,1.672Zm5.853,14.215A2.509,2.509,0,0,1,12.871,18.4H7.853a2.509,2.509,0,0,1-2.509-2.509V5.017H15.379Z"
                    transform="translate(-2)"
                    fill="#FFF"
                  />
                  <path
                    id="Path_659"
                    data-name="Path 659"
                    d="M9.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,9.836,16.69Z"
                    transform="translate(-3.147 -1.638)"
                    fill="#FFF"
                  />
                  <path
                    id="Path_660"
                    data-name="Path 660"
                    d="M13.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,13.836,16.69Z"
                    transform="translate(-3.802 -1.638)"
                    fill="#FFF"
                  />
                </svg>
              }
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="comparison">
      {generateHeader(props.data)}
      {generateBody(props.data)}
      {generateFooter(props.data)}
    </div>
  );
};
export default CompareTable;
