import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

export default function DellWarranty() {
  return (
    <>
      <Breadcrumbs active_page="Dell 5 vite garancion" crumbs={[]} />
      <div className="container">
        <h3 className="page-title">DELL 5y5b</h3>
        <div className="terms-container privacy-container">
          <div>
            <p style={{ fontWeight: 400 }}>
              Në rast të ndonjë dështimi të funksionimit të paisjes si pasojë e
              fabrikës për <b>5 vite</b> nga blerja e pajisjes, klientët kanë të
              drejtë për shërbim falas* në "<b>FIKSON Service</b>" i autorizuar
              nga Dell. *Kushti i garancisë për 5 VITE është që konsumatorët të
              sjellin pajisjen e tyre në për kontroll parandaluese një herë në
              vit - <b>FALAS</b>.
            </p>
          </div>
          <div>
            <h3 style={{ fontWeight: 600 }}>5 VITE MIRËMBAJTJE FALAS</h3>
            <p style={{ fontWeight: 400 }}>
              Për të garantuar funksionimin e mirë të pajisjeve Dell, klientëve
              u ofrohet shërbim vjetor parandalues kompjuterik falas në qendren
              e shërbimit <b>FIKSON Service - Prishtinë</b>.<br />
              Gjatë shërbimit, teknikët kontrollojnë funksionalitetin e plotë të
              kompjuterit nga ana harduerike.
            </p>
          </div>
          <div>
            <h3 style={{ fontWeight: 600 }}>RIKTHIMI I TË DHËNAVE</h3>
            <p style={{ fontWeight: 400 }}>
              Rikthimi i të dhënave është proces i rikuperimit të të dhënave nga
              hard disqet e dëmtuara ose të koduar ose SSD për ruajtjen e të
              dhënave. Procesi i rikuperimit të të dhënave kryhet në laboratorët
              e specializuar të "FIKSON Service", qendër e servisit të
              autorizuar nga <b>Dell</b>.<br />
              <i>
                * Shërbimi ofrohet pa pagesë një herë në 5 vite të përdorimit.
              </i>
            </p>
          </div>
          <div>
            <h3 style={{ fontWeight: 600 }}>MBËSHTETJE NGA DISTANCA</h3>
            <p style={{ fontWeight: 400 }}>
              Përdoruesve u sigurohet një orë mbështetje on-line (e cila mund të
              përdoret në përsëritje të shumta) për kohëzgjatjen e kësaj
              garancie. Mbështetja ofrohet nëpërmjet internetit, nëpërmjet
              lidhjes në distancë të inxhinierëve nëpërmjet TeamViewer.
              Nëpërmjet këtij lloji të mbështetjes, inxhinierët janë në gjendje
              të zgjidhin disa nga problemet më të ndërlikuara, pa qenë nevoja
              që klienti të shkojë në qendrën e Servisit.
            </p>
          </div>
          <div>
            <h3 style={{ fontWeight: 600 }}>
              MBËSHTETJE E PAKRAHASUAR TË PAISJEVE DELL
            </h3>
            <p style={{ fontWeight: 400 }}>
              Ja pse Dell është marka e vetme që mund t'ju ofrojë një garanci 5
              vjeçare!* <br />
              <i>
                * Garancia pesëvjeçare e Dell-it vlen për të gjitha modelet e
                laptopëve Dell, përveç Latitude dhe Precision.
              </i>
            </p>
          </div>
          <div>
            <p style={{ fontWeight: 400 }}>
              Adresa e Servisit:
              <br />
              FIKSON Service
              <br />
              Rr. Ali Vitia Nr. 225, Lagjia Emshir, 10000 Prishtinë, Kosovë
              <br />
              Email: service@pc-ks.net
              <br />
              Telefon: +383 (0) 49 438 038
              <br />
              Orari i punës: 09:00 — 17:00 (Nga e hëna deri të shtunën)
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
