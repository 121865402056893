import { 
  GET_BANNERS,
  SET_BANNERS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isLoading: false,
  banners: null
};

export const bannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return {
        ...state,
        isLoading: true
      };
    case SET_BANNERS:
      return {
        ...state,
        isLoading: false,
        banners: action.banners
      };
    default:
      return state
  }
};

export default bannersReducer;