import { put, takeLatest } from 'redux-saga/effects';
import { registerSuccess, registerError } from './actions'
import { setMessage } from '../custom/actions'
import { REGISTER } from './types'
import api from '../../../utils/axios'

// watcher
export function* registerWatcher() {
  yield takeLatest(REGISTER, INIT);
}

// worker
export function* INIT({ user }) {
  try {
    yield api.post('/users', {
      email: user.email,
      user_type: 'C',
      company_id: 1,
      status: 'A',
      firstname: user.name,
      lastname: user.lastname,
      is_root: 'N',
      password: user.password
    });

    yield put(setMessage('success', "You have registered successfully."))
    yield put(registerSuccess(user.email))
  } catch (e){
    yield put(setMessage('error', "The email you have chosen already exists. Please try another email."))
    yield put(registerError(e.response.data.message))
  }
}