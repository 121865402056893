/********************
 ACTION TYPES
 ********************/

 export const GET_SEARCH = "GET_SEARCH";
 export const SET_SEARCH = "SET_SEARCH";

 export const GET_SEARCH_CATEGORIES = "GET_SEARCH_CATEGORIES";
 export const SET_SEARCH_CATEGORIES = "SET_SEARCH_CATEGORIES";

 export const GET_SEARCH_FILTERS = "GET_SEARCH_FILTERS";
 export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";