import { 
  AUTHENTICATION_SUCCEED, 
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_FAILURE
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isAuthenticated: false,
  isLoading: false,
  token: null,
  exp: null,
  user: null
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_LOGIN:
      return {
        ...state,
        isLoading: true
      };
    case AUTHENTICATION_SUCCEED:
      return {
        ...state,
        isAuthenticated: true,
        error: null,
        token: action.token,
        user: action.user,
        isLoading: false
      };
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case AUTHENTICATION_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: null,
        user: null
      };

    default:
      return state
  }
};

export default authentication;