import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "../Custom";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, clearSuccess } from "../../redux/app/profile/actions";
import { setNotes } from "../../redux/app/orders/actions";
import { createOrder } from "../../redux/app/orders/actions";
import { Link, useNavigate } from "react-router-dom";
import { fixPrice } from "../../utils/number";

export default function OrderDetails({ show }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isBeingCreated, created, order_id, notes } = useSelector(
    (state) => state.orders
  );
  const { user, isSubmitting, success } = useSelector((state) => state.profile);
  const { subtotal, couponDiscount, total } = useSelector(
    (state) => state.cart
  );
  const [cashActive, setCashActive] = useState(false);
  const [bankActive, setBankActive] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const cashPayment = () => {
    if (cashActive) {
      setBankActive(false);
    } else {
      setCashActive(true);
    }
  };

  const bankPayment = () => {
    if (bankActive) {
      setCashActive(false);
    } else {
      setBankActive(true);
    }
  };

  const BuySchema = Yup.object().shape({
    name: Yup.string().required("Emri eshte i detyrueshem"),
    lastname: Yup.string().required("Mbiemri eshte i detyrueshem"),
    address: Yup.string().required("Adresa e transportit eshte e detyrueshme"),
    city: Yup.string().required("Qyteti i transportit eshte i detyrueshem"),
    phone: Yup.string().required("Numri i telefonit eshte i detyrueshem"),
    s_address: Yup.string().required(
      "Adresa e transportit eshte e detyrueshme"
    ),
    s_city: Yup.string().required("Qyteti i transportit eshte i detyrueshem"),
    payment_id: Yup.string().required("Zgjedhni menyren e pageses."),
    terms: Yup.boolean()
      .required()
      .oneOf([true], "Ju duhet te pajtoheni me termet dhe kushtet"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: BuySchema,
    enableReinitialize: true,
    values: {},
    errors: {},
    touched: {},
    onSubmit: (values) => {
      dispatch(createOrder(values));
      dispatch(updateUser(values, user));
      dispatch(setNotes(values.notes));
    },
  });

  useEffect(() => {
    setInitialValues({
      name: user?.fields["C"]?.fields[0]?.value || "",
      lastname: user?.fields["C"]?.fields[1]?.value || "",
      region: "AL",
      address: user?.fields["B"]?.fields[3]?.value || "",
      city: user?.fields["B"]?.fields[5]?.value || "",
      phone: user?.fields["C"]?.fields[3]?.value || "",
      email: user?.fields["E"]?.fields[0]?.value || "",
      s_address: user?.fields["S"]?.fields[2]?.value || "",
      s_city: user?.fields["B"]?.fields[5]?.value || "",
      notes: notes || "",
      payment_id: "",
      terms: false,
      shipping_id: 1,
    });
  }, [user]);

  useEffect(() => {
    if (!isSubmitting && success);
    return () => dispatch(clearSuccess());
  }, [isSubmitting, success]);

  useEffect(() => {
    if (order_id && created && !isBeingCreated) navigate("/cart/success");
  }, [isBeingCreated, created, order_id]);

  return (
    <>
      <p style={{ fontSize: "18px", color: "gray" }}>Detajet e fatures</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-order-container">
          <div className="form">
            <div className="form-row-details">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Emri*</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Emri"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <ErrorMessage message={formik.errors.name} />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">Mbiemri*</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Mbiemri"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                  />
                  {formik.errors.lastname && formik.touched.lastname && (
                    <ErrorMessage message={formik.errors.lastname} />
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="phone">Numri telefonit*</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Numri i telefonit"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <ErrorMessage message={formik.errors.phone} />
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="city">Qyteti*</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Qyteti"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.errors.city && formik.touched.city && (
                    <ErrorMessage message={formik.errors.city} />
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="address">Adresa*</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Adresa"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  {formik.errors.address && formik.touched.address && (
                    <ErrorMessage message={formik.errors.address} />
                  )}
                </div>
              </div>

              {show && (
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="buissnessCode">Kodi i biznesit</label>
                    <input
                      id="buissnessCode"
                      name="buissnessCode"
                      placeholder="Kodi i biznesit"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.buissnessCode}
                    ></input>
                  </div>
                </div>
              )}

              {/* Address details */}

              <h4 className="sub-title">Detajet e transportit</h4>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="s_city">Qyteti*</label>
                  <input
                    type="text"
                    id="s_city"
                    name="s_city"
                    placeholder="Qyteti"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.s_city}
                  />
                  {formik.errors.s_city && formik.touched.s_city && (
                    <ErrorMessage message={formik.errors.s_city} />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="s_address">Adresa*</label>
                  <input
                    type="text"
                    id="s_address"
                    name="s_address"
                    placeholder="Adresa"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.s_address}
                  />
                  {formik.errors.s_address && formik.touched.s_address && (
                    <ErrorMessage message={formik.errors.s_address} />
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="notes">Shenimet e porosise (opcional)</label>
                  <textarea
                    rows="5"
                    id="notes"
                    name="notes"
                    placeholder="Shenimet e porosise (opcional)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notes}
                  ></textarea>
                </div>
              </div>

              <div className="d-flex justify-center">
                {/* <Button 
              type='submit'
              classes='green continue'
              title='Ruaj'
              loading={ isSubmitting }
            /> */}
              </div>
            </div>

            {/*-------------order summary--------------------*/}

            <div className="order-summary-container">
              <div className="order-summary-heading">
                <h5 className="heading">Përmbledhja e porosisë</h5>
              </div>
              {/* <div className={`search-results small-products`}>
              <div className="search-scroll">
                { length > 0 && cartProducts.map(item => (
                  <SmallProduct key={ item.product_id } showQty={true} showPrice={true} item={ item } />
                )) }
              </div>
            </div> */}

              <div className="cart-totals">
                <div className="cart-total-order" style={{ paddingBottom: 0 }}>
                  <p>Nën Totali</p>
                  <p style={{ color: "#EA1B25", fontWeight: "bold" }}>
                    {fixPrice(subtotal)} &euro;
                  </p>
                </div>

                <div
                  className="cart-total-order"
                  style={{ borderBottom: "2px solid #D6D6D6" }}
                >
                  <p>Koha e arritjes së produktit</p>
                  <p style={{ color: "#EA1B25", fontWeight: "bold" }}>
                    00.00 &euro;
                  </p>
                </div>

                {couponDiscount > 0 && (
                  <div className="cart-total-order">
                    <p>Zbritje kuponi</p>
                    <p style={{ color: "#EA1B25", fontWeight: "bold" }}>
                      -{fixPrice(couponDiscount)} &euro;
                    </p>
                  </div>
                )}

                <div className="cart-total-order-total">
                  <h3>TOTALI</h3>
                  <h3 style={{ color: "#EA1B25", fontWeight: "bold" }}>
                    {fixPrice(total)} &euro;
                  </h3>
                </div>
              </div>

              <div className="cart-total-order-paragraph">
                <p>
                  Të dhënat tuaja personale do të përdoren për të përpunuar
                  porosinë tuaj, për të mbështetur përvojën tuaj në të gjithë
                  këtë faqe interneti dhe për qëllime të tjera të përshkruara në
                  privacy policy.
                </p>
              </div>
              <label className="custom-checkbox">
                Kam lexuar dhe pajtohem me faqen{" "}
                <Link to="/">Terms & Conditions</Link>*
                {/* <Field type="checkbox" name="terms"  /> */}
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.terms}
                />
                <span className="checkmark"></span>
              </label>
              {formik?.errors.terms && formik?.touched.terms && (
                <ErrorMessage message={formik?.errors.terms} />
              )}

              <div className="place-order-container-order">
                <div className="place-order">
                  <label
                    className="costum-radio"
                    onClick={cashPayment}
                    style={{
                      backgroundColor: cashActive ? "#EA1B25" : "",
                      color: cashActive ? "white" : "",
                    }}
                  >
                    <div className="method-title">
                      <span>Pagesa me para në dorë</span>
                      {/* <span>KESH</span> */}
                    </div>
                    {/* <Field type="radio" name="payment_id" value="6" /> */}
                    <input
                      type="radio"
                      id="payment_id"
                      name="payment_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={"6"}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label
                    className="costum-radio"
                    onClick={bankPayment}
                    style={{
                      backgroundColor: bankActive ? "#EA1B25" : "",
                      color: bankActive ? "white" : "",
                    }}
                  >
                    <div className="method-title">
                      <span>Pagesa me bankë</span>
                    </div>
                    <input
                      type="radio"
                      id="payment_id"
                      name="payment_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={"5"}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {formik?.errors.payment_id && formik?.touched.payment_id && (
                  <ErrorMessage message={formik?.errors.payment_id} />
                )}
                {formik?.values.payment_id === "5" && (
                  <div className="bank-transfer">
                    <p className="margin-bottom">
                      Detajet për pagesë me transfer bankarë:
                    </p>
                    <p>
                      <span>Emri i Kompanisë:</span> POINT COMPUTERS SH.P.K
                    </p>
                    <p className="margin-bottom">
                      <span>Adresa:</span> Rr. Eqrem Qabej Nr. 76, 10000
                      Prishtinë, Republika e Kosovës
                    </p>
                    <p>
                      <span>Llogaritë Bankare:</span>
                    </p>
                    <p>
                      <span>ProCredit Bank:</span> 1183010384000177
                    </p>
                    <p>
                      <span>Raiffeisen Bank:</span> 1501030001959864
                    </p>
                    <p className="margin-bottom">
                      <span>TEB Bank:</span> 2020000101444227
                    </p>
                    <p>
                      Gjat pagesës me transfer bankarë, ju lutemi të cekni
                      numrin e Faturës në mënyrë që porosia juaj të procesohet
                      sa më shpejtë.
                    </p>
                  </div>
                )}

                {formik?.values.payment_id === "6" && (
                  <Button
                    type="submit"
                    title="Beje porosine"
                    loading={isBeingCreated}
                    classes="green border-radius-4 place-order-btn"
                  />
                )}

                {formik?.values.payment_id === "5" && (
                  <Button
                    type="submit"
                    title="Beje porosine"
                    loading={isBeingCreated}
                    classes="green border-radius-4 place-order-btn"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
