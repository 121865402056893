import { 
  SET_ALERT_MESSAGE
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  alert: null
};

export const customReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alert: {
          type: action.msgType,
          msg: action.msg
        },
      };
    default:
      return state
  }
};

export default customReducer;