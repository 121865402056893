import React from "react";
import { Outlet } from "react-router-dom";
import PreferedModels from "../components/Home/PreferedModels";

export default function Cart() {
  return (
    <>
      <Outlet />
      <PreferedModels rows={1} heading="Trendet e fundit" />
    </>
  );
}
