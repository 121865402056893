import React, { useEffect, useState } from "react";
import Banner from "../components/Home/Banner";
import Featured from "../components/Home/Featured";
import Trio from "../components/Home/Trio";
import Products from "../components/Home/Products";
import Categories from "../components/Home/Categories";
import PreferedModels from "../components/Home/PreferedModels";
import PreferedModelsHome from "../components/Home/PreferedModelsHome";
import { getDeals } from "../redux/app/deals/actions";
// import Marquee from 'react-double-marquee';
import Button from "../components/Button";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../redux/app/products/all/actions";
import Banners from "../components/Home/Banners";
import SliderImages from "../components/Home/Slider";
import PreferedModelsHomeEnd from "../components/Home/PreferedModelsHomeEnd";
// import {HomeBanner} from '../../assets/images/maskhome.png';
import { getBanners } from "../redux/app/banners/actions";

function Home() {
 const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
 const { products, isLoading, page } = useSelector((state) => state.products);
 const dispatch = useDispatch();
 const getMoreProducts = () => {
  dispatch(getProducts({ page: page + 1 }));
 };
 useEffect(() => {
  if (products.length <= 0) dispatch(getProducts({ page }));
 }, [dispatch]);
 const { banners } = useSelector((state) => state.banners);

 useEffect(() => {
  dispatch(getDeals());
  dispatch(getBanners());
 }, [dispatch]);

 console.log(banners, "bannerssss");

 return (
  <>
   <Banner />
   <Trio />

   <Featured />

   {!isMobile && <Categories isMobile={isMobile} />}

   <Products products={products} isLoading={isLoading} />

   <Banners />

   <PreferedModels rows={2} heading="" />

   <div className="container">
    <div className="brands">
     <div className="item">
      <img src="/assets/images/brands/dell.svg" alt="Dell" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/asus.svg" alt="Asus" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/apple.svg" alt="Apple" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/razer.svg" alt="Razer" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/acer.svg" alt="Acer" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/logitech.svg" alt="Logitech" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/aorus.svg" alt="Aorus" />
     </div>
     <div className="item">
      <img src="/assets/images/brands/corsair.svg" alt="Corsair" />
     </div>
    </div>
   </div>

   {!isMobile && (
    <div className="container">
     <div className="last_banner">
      {banners && banners.length > 0 && (
       <div style={{ position: "relative" }}>
        <a href={banners[0].url}>
         <img
          style={{
           width: "600px",
           display: "block",
           margin: "0 auto",
          }}
          src={banners[0].main_pair.icon.image_path}
          alt="Banner Image"
         />
        </a>
       </div>
      )}
      <div className="models">
       <PreferedModelsHome rows={2} heading="" />
      </div>
     </div>
    </div>
   )}
  </>
 );
}
export default Home;
