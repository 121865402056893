/********************
 ACTIONS
 ********************/
 import { 
  GET_PRODUCTS, 
  SET_PRODUCTS
} from './types'

export const getProducts = (data) => ({
  type: GET_PRODUCTS,
  data
});

export const setProducts = (products) => ({
  type: SET_PRODUCTS,
  products
});