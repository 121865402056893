import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay } from "swiper/core";
import { EffectFade, Navigation, Pagination } from "swiper";
SwiperCore.use([Autoplay]);

const SliderImages = (props) => {
  const [slider, setSlider] = useState([
    {
      id: 1,
      // imgUrl: Background,
    },
    {
      id: 2,
      imgUrl:
        "https://point-computers.com/wp-content/uploads/2022/04/HyperXcloudIIBanner.jpg",
    },
    {
      id: 3,
      imgUrl:
        "https://point-computers.com/wp-content/uploads/2022/02/dell-banner-PNG.png",
    },
    {
      id: 4,
      imgUrl:
        "https://point-computers.com/wp-content/uploads/2022/04/LogitechBanner.jpg",
    },
  ]);

  return (
    <>
      <div>
        <Swiper
          spaceBetween={0}
          effect={"fade"}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          autoplay={{
            delay: 2000,
          }}
        >
          {slider.map((item, index) => (
            <SwiperSlide key={index}>
              <div>
                {/* <img src={item.imgUrl} alt='/' className={styles.background}/> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default SliderImages;
