/********************
 ACTION TYPES
 ********************/

 export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
 export const SET_SINGLE_PRODUCT = "SET_SINGLE_PRODUCT";

 export const GET_SINGLE_PRODUCT_INFO = "GET_SINGLE_PRODUCT_INFO";
 export const SET_SINGLE_PRODUCT_INFO = "SET_SINGLE_PRODUCT_INFO";

 export const GET_SIMILAR_PRODUCTS = "GET_SIMILAR_PRODUCTS";
 export const SET_SIMILAR_PRODUCTS = "SET_SIMILAR_PRODUCTS";

 export const SET_PRODUCT_CRUMBS = "SET_PRODUCT_CRUMBS";

 export const GET_REVIEWS = "GET_REVIEWS";
 export const SET_REVIEWS = "SET_REVIEWS";
 export const ADD_REVIEW = "ADD_REVIEW";
 export const ADD_REVIEW_RESPONSE = "ADD_REVIEW_RESPONSE";