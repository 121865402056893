import { 
  CHECK_RESET, 
  CHECK_RESET_RESPONSE, 
  RESET_PASSWORD, 
  RESET_PASSWORD_RESPONSE 
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isLoading: false,
  isOk: undefined,
  user: undefined,
  isSubmitting: false,
  isSuccess: false
};

export const resetReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_RESET:
      return {
        ...state,
        isLoading: true
      };
    case CHECK_RESET_RESPONSE:
      return {
        ...state,
        isLoading: false,
        isOk: action.status,
        user: action.user
      };

    case RESET_PASSWORD:
      return {
        ...state,
        isSubmitting: true
      };

    case RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        isSubmitting: false,
        isSuccess: action.status
      };
    default:
      return state
  }
};

export default resetReducer;