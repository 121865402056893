import { 
  GET_FEATURED,
  SET_FEATURED
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  featured: null,
};

export const featuredReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURED:
      return {
        ...state,
        isLoading: true
      }
    case SET_FEATURED:
      return {
        ...state,
        featured: action.featured,
        isLoading: false
      }

    default:
      return state
  }
};

export default featuredReducer;