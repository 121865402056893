import React from "react";
import { SmallProduct } from "../Product";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeProductFromCart } from "../../redux/app/cart/actions";
import { clearCart } from "../../redux/app/cart/actions";
import { addAllToWishlist } from "../../redux/app/cart/actions";
import Button from "../Button";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmModal from "../ConfirmModal";
import { clearCartDB } from "../../redux/app/cart/actions";

export default function SideShoppingBag({ setVisibility }) {
  const { cartProducts, length } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            onClose={onClose}
            onRemove={() => dispatch(removeProductFromCart(id))}
          />
        );
      },
      overlayClassName: "confirmation-modal-overlay",
    });
  };

  const handleClear = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            onClose={onClose}
            onRemove={() => { dispatch(clearCart()); dispatch(clearCartDB()); localStorage.removeItem("cart") }}
          />
        )
      },
      overlayClassName: "confirmation-modal-overlay",
    })
  }

  return (
    <>
      <div className="items">
        <div className="header">
          <h5>Shporta ({("0" + length).slice(-2)})</h5>
          <Button
            classes="c-pointer"
            onClick={() => setVisibility(false)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <path
                  id="fi-rr-cross-small"
                  d="M15.475,5.94h0a.8.8,0,0,0-1.124,0L10.707,9.584,7.063,5.94a.8.8,0,0,0-1.124,0h0a.8.8,0,0,0,0,1.124l3.644,3.644L5.94,14.351a.8.8,0,0,0,0,1.124h0a.8.8,0,0,0,1.124,0l3.644-3.644,3.644,3.644a.8.8,0,0,0,1.124,0h0a.8.8,0,0,0,0-1.124l-3.644-3.644,3.644-3.644A.8.8,0,0,0,15.475,5.94Z"
                  transform="translate(-5.707 -5.707)"
                  opacity="0.4"
                />
              </svg>
            }
          />
        </div>

        <div className="small-products">
          <div className="search-scroll">
            {cartProducts &&
              cartProducts.map((item) => (
                <SmallProduct
                  key={item.product_id}
                  item={item}
                  showPrice={true}
                  showQty={true}
                  onClick={() =>
                    handleRemove(item?.id ? item.id : item.product_id)
                  }
                />
              ))}
            {length <= 0 && <p>Shporta juaj eshte e zbrazet.</p>}
          </div>
        </div>
      </div>
      <div className="actions">
        <a href="/cart" className="outline blue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <path
              id="fi-rr-shopping-bag"
              d="M14.875,4.25H12.75a4.25,4.25,0,1,0-8.5,0H2.125A2.125,2.125,0,0,0,0,6.375v7.083A3.546,3.546,0,0,0,3.542,17h9.917A3.546,3.546,0,0,0,17,13.458V6.375A2.125,2.125,0,0,0,14.875,4.25ZM8.5,1.417A2.833,2.833,0,0,1,11.333,4.25H5.667A2.833,2.833,0,0,1,8.5,1.417Zm7.083,12.042a2.125,2.125,0,0,1-2.125,2.125H3.542a2.125,2.125,0,0,1-2.125-2.125V6.375a.708.708,0,0,1,.708-.708H4.25V7.083a.708.708,0,0,0,1.417,0V5.667h5.667V7.083a.708.708,0,0,0,1.417,0V5.667h2.125a.708.708,0,0,1,.708.708Z"
              transform="translate(0 0)"
              fill="#FFF"
            />
          </svg>
          Shiko shporten
        </a>
        <button className="outline blue" onClick={() => handleClear()}>
          <svg
            id="fi-rr-trash"
            xmlns="http://www.w3.org/2000/svg"
            width="16.724"
            height="20.069"
            viewBox="0 0 16.724 20.069"
          >
            <path
              id="Path_658"
              data-name="Path 658"
              d="M17.888,3.345H15.3A4.189,4.189,0,0,0,11.2,0H9.526a4.189,4.189,0,0,0-4.1,3.345H2.836a.836.836,0,1,0,0,1.672h.836V15.888a4.186,4.186,0,0,0,4.181,4.181h5.017a4.186,4.186,0,0,0,4.181-4.181V5.017h.836a.836.836,0,0,0,0-1.672ZM9.526,1.672H11.2a2.514,2.514,0,0,1,2.366,1.672H7.16A2.514,2.514,0,0,1,9.526,1.672Zm5.853,14.215A2.509,2.509,0,0,1,12.871,18.4H7.853a2.509,2.509,0,0,1-2.509-2.509V5.017H15.379Z"
              transform="translate(-2)"
              fill="#fff"
            />
            <path
              id="Path_659"
              data-name="Path 659"
              d="M9.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,9.836,16.69Z"
              transform="translate(-3.147 -1.638)"
              fill="#fff"
            />
            <path
              id="Path_660"
              data-name="Path 660"
              d="M13.836,16.69a.836.836,0,0,0,.836-.836V10.836a.836.836,0,0,0-1.672,0v5.017A.836.836,0,0,0,13.836,16.69Z"
              transform="translate(-3.802 -1.638)"
              fill="#fff"
            />
          </svg>
          Zbraze shportën
        </button>
        {/* <Button 
          classes='outline green'
          title='Shto ne listen e deshirave'
          onClick={ () => dispatch(addAllToWishlist()) }
          icon= { 
            <svg xmlns="http://www.w3.org/2000/svg" width="19.06" height="16.747" viewBox="0 0 19.06 16.747">
              <path id="fi-rr-heart" d="M13.892,1.917a5.081,5.081,0,0,0-4.366,2.62,5.081,5.081,0,0,0-4.366-2.62A5.4,5.4,0,0,0,0,7.514c0,3.61,3.8,7.552,6.986,10.225a3.949,3.949,0,0,0,5.081,0c3.187-2.673,6.986-6.615,6.986-10.225a5.4,5.4,0,0,0-5.16-5.6ZM11.046,16.524a2.36,2.36,0,0,1-3.04,0C3.926,13.1,1.587,9.818,1.587,7.514A3.811,3.811,0,0,1,5.159,3.5a4.094,4.094,0,0,1,3.572,2.89.794.794,0,0,0,1.588,0A4.146,4.146,0,0,1,13.892,3.5a3.811,3.811,0,0,1,3.572,4.009c0,2.3-2.34,5.588-6.418,9.007Z" transform="translate(0.005 -1.917)" fill="#0e9548"/>
            </svg>
          }
        /> */}
      </div>
    </>
  );
}
