/********************
 ACTION TYPES
 ********************/

export const GET_CART = "GET_CART";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_CART = "UPDATE_CART";
export const CART_LENGTH = "CART_LENGTH";
export const CART_INC = "CART_INC";
export const CART_DEC = "CART_DEC";
export const RESET_ADDED = "RESET_ADDED";

export const ADD_ALL_TO_WISHLIST = "ADD_ALL_TO_WISHLIST";

export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_CART_DB = "CLEAR_CART_DB";

export const ADD_TO_DB = "ADD_TO_DB";

export const CART_TOTAL = "CART_TOTAL";

export const COUPON_INIT = "COUPON_INIT";
export const GET_COUPON = "GET_COUPON";
export const SET_COUPON = "SET_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";