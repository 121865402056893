/********************
 ACTIONS
 ********************/
 import { 
  GET_TRENDS_PRODUCTS, 
  SET_TRENDS_PRODUCTS
} from './types'

export const getProducts = (data, parent = false) => ({
  type: GET_TRENDS_PRODUCTS,
  data,
  parent
});

export const setProducts = (products, parent) => ({
  type: SET_TRENDS_PRODUCTS,
  products,
  parent
});