/********************
 ACTIONS
 ********************/
 import { 
  GET_CATEGORIES,
  SET_CATEGORIES
} from './types'

export const getCategories = (parent) => ({
  type: GET_CATEGORIES,
  parent
});

export const setCategories = (categories, allCategories) => ({
  type: SET_CATEGORIES,
  categories,
  allCategories
});