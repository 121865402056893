import { put, call, takeLatest } from 'redux-saga/effects';
import { setSeachFilters, setSearch, setSearchCategory } from './actions'
import { GET_SEARCH, GET_SEARCH_FILTERS, GET_SEARCH_CATEGORIES } from './types'
import api from '../../../utils/axios'
import { sorting } from './functions';

// watcher
export function* searchWatcher() {
  yield takeLatest(GET_SEARCH, INIT);
  yield takeLatest(GET_SEARCH_FILTERS, GETSEARCHFILTERS);
  yield takeLatest(GET_SEARCH_CATEGORIES, GETSEARCHCATEGORIES);
}

function* getCategory(ctgId){
  const category = (yield api.get(`/categories/${ctgId}`)).data
  yield put(setSearchCategory({
    id: category.category_id,
    title: category.category,
    link: category.seo_name
  }))

  if(category.parent_id !== "0") yield call(getCategory, category.parent_id); 
}

// worker
export function* INIT({ data }) {
  try {
    var keyword = null;
    const url = data.c ? `/categories/${data.c}/products` : `/products`;
    if(data?.q) keyword = { pname: 'Y', pcode_from_q: 'Y', pshort: 'Y', pfull: 'Y', q: data.q }
    const { products, params } = (yield api.get(url, {
      params: {
        amount_from: 0,
        status: 'A',
        items_per_page: 28,
        subcats: 'Y',
        cid: data?.c,
        ...sorting(data?.order),
        ...keyword,
        ...data
      }
    })).data;
    yield put(setSearch(products, params));
  } catch (e){
    console.log(e);
  }
}

export function* GETSEARCHFILTERS({ categoryId }){
  try {
    const dt = yield api.get(`4.0/filters`, { params: { 
      category_id: categoryId,
      amount_from: 0,
      status: 'A'
    } });
    yield put(setSeachFilters(dt?.data[0]))
  }catch (e){ 
    console.log(e);
  }
}

export function* GETSEARCHCATEGORIES({ categoryId }){
  try {
    yield call(getCategory, categoryId);
  }catch (e){ 
    console.log(e);
  }
}