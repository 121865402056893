import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="container">
      <div className="d-flex flex-column align-center">
        <img
          src="/assets/images/404imagebanner.png"
          alt="404"
          className="not-found-img"
        />
        <h1 style={{ color: "#EA1B25" }}>Faqja qe kerkoni nuk u gjet !</h1>
        <div style={{ paddingBottom: "50px", paddingTop: "20px" }}>
          <Link className="outline blue" to="/">
            Kthehu ne balline
          </Link>
        </div>
      </div>
    </div>
  );
}

export const NotFoundProduct = () => {
  return (
    <div className="container">
      <div className="d-flex flex-column align-center">
        <img
          src="/assets/images/404imagebanner.png"
          alt="404"
          className="not-found-img"
        />
        <h1 style={{ color: "#EA1B25" }}>Produkti qe kerkoni nuk u gjet !</h1>
        <Link className="outline blue" to="/">
          Kthehu ne balline
        </Link>
      </div>
    </div>
  );
};
