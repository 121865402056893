import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Link } from "react-router-dom";

export default function AboutOrder() {
  return (
    <>
      <Breadcrumbs active_page="Porosia" crumbs={[]} />
      <div className="container">
        <h3 className="page-title">Porosia</h3>
        <div className="terms-container privacy-container">
          <div>
            <p>
              Porosia <br />
              Si mund ta bëj porosinë? <br />
              Porosinë mund ta bëni online direkt në faqe{" "}
              <Link style={{ color: "#EA1B25" }} to="/">
                https://pointcomputers.com
              </Link>
            </p>
            <h3>A duhet të regjistrohem për të bërë porosi?</h3>
            <p>
              Po për të bërë porosinë, duhet të regjistroheni si përdorues, I
              tërë regjistrimi zgjat jo më shumë se 1 minutë.
              <ul>
                <li>
                  Së pari duhet të klikoni në butonin “Krijo llogari”, i cili
                  gjendet në shiritin e sipërm të faqes në anën e djathtë apo
                  klikoni këtu:{" "}
                  <Link style={{ color: "#EA1B25" }} to="/register">
                    https://pointcomputers.com/register
                  </Link>
                </li>
                <li>
                  Pasi ta të jeni në faqen e regjistrimit, do të hapet faqja ku
                  duhet t’i shënoni të dhënat tuaja personale si Emri dhe
                  Mbiemri, E-mail adresa dhe Fjalëkalimi për llogarinë tuaj në
                  Point Computers
                </li>
                <li>
                  Pas regjistrimit, do te riktheheni në mënyrë automatike në
                  faqen fillestare prej së cilës tani mund të bëni porosi me
                  llogarinë e krijuar dhe më të dhënat qe ju i keni shënuar.
                </li>
              </ul>
            </p>
            <h3>Si ta shfrytëzoj kodin promocional?</h3>
            <p>
              Gjatë procesit të blerjes, tek shporta juaj gjendet hapësira
              &quot;Gift certificate or promo code&quot; në të cilën mund ta
              shkruani kodin që posedoni dhe në fund të klikoni në butonin
              &quot; Shto &quot;, në mënyrë që diferenca të largohet nga totali
              i porosisë.
            </p>
            <h3>
              A mund të bëj porosi përmes telefonit, rrjeteve sociale apo live
              chat?
            </h3>
            <p>
              Porositë duhet të bëhen direkt në faqen tonë nga vet përdoruesi
              mirëpo për çfardo problemi Teknik apo ndihmë rreth përfundimit të
              porosisë ju mund ta kontaktoni në rrjete sociale apo telefon{" "}
              <br />
              <span style={{ fontSize: 12 }}>
                * * Prej ores 10:00 deri ora 21:00
              </span>
            </p>
            <h3>A mund të blej produkte në emër të kompanisë?</h3>
            <p>
              Patjeter, ju lëshojmë dhe faturë të rregullt për porosinë që bëni,
              mjafton të shënoni të dhënat e biznesit te të dhënat e porosisë
              (emrin ligjor të biznesit dhe numrin unik), pas porosisë do
              t&#39;ju dërgojmë profaturën në email, ndërsa faturën e rregullt e
              pranoni bashkë me porosinë tuaj.
            </p>
            <h3>Ku bëhen porositë dhe ku gjendet porosia ime në faqe?</h3>
            <p>
              Porositë bëhen duke klikuar &quot;<b>Shto ne shporte</b>&quot; në
              faqen e produktit. <br />
              Porositë tuaja mund t&#39;i shihni kur të keni klikuar mbi
              llogarinë tuaj në të djathtë &quot;Emri Mbiemri&quot; dhe pastaj
              te hapësira &quot;Historia e porosive&quot;.
            </p>
            <h3>Kur anulohen porositë nga pointcomputers.com?</h3>
            <p>
              <ul>
                <li>
                  - Nëse për çfarëdo arsye porosia e bërë juaj është e dyshimtë,
                  atëherë bëhet anulimi i porosisë dhe dërgohet një email
                  informues lidhur mbi anulimin e porosisë.
                </li>
                <li>
                  - Nëse ju kemi thirrur në telefon për të konfirmuar porosinë e
                  bërë dhe nuk jeni lajmëruar, atëherë ajo porosi do të
                  anulohet, ku edhe do të njoftoheni përmes emailit.
                </li>
                <li>
                  - Nëse adresa e shënuar për dorzimin e produkteve nuk përmban
                  të dhënat e sakta për vendndodhjen tuaj dhe nëse edhe pas
                  kontaktimit nuk ofroni informatat e nevojshme shtesë, atëherë
                  ajo porosi do të anulohet.
                </li>
                <li>
                  - Nëse dy herë është tentuar që t&#39;u sillet porosia në
                  adresën tuaj dhe nuk keni qenë në shtëpi/zyre për ta pranuar
                  atë, atëherë ajo porosi do të anulohet.
                </li>
              </ul>
            </p>
            <h3>Blacklist – çfarë është ajo?</h3>
            <p>
              Blacklist është listë e përdoruesve që duhet të parapaguajnë për
              çdo porosi që bëjnë, për shkak se kanë bërë anulim të vonshëm apo
              kanë refuzuar një porosi të mëherëshme. Në këto raste, përdoruesi
              kalon në mënyrë automatike në këtë listë.
            </p>
            <h3>Si informohem që porosia ime është në rregull?</h3>
            <p>
              Nëpër secilin hap që kalon porosia, ju pranoni e-mail për statusin
              aktual të dërgesës suaj.
            </p>
            <h3>A mund ta kthej porosinë?</h3>
            <p>
              Porosia mund të kthehet në afat prej 6 ditësh nga dita kur është
              blerë produkti, vetëm në gjendjen që është pranuar me paketim,
              kupon fiskal dhe fletëgarancion. Nëse njëri nga komponentët e
              shënuar mungon atëher kthimi llogaritet jo I plotë dhe juve ju
              mohohet e drejta për kthim.
            </p>
            <h3>A mund ta ndryshoj adresën pasi që është nisur porosia?</h3>
            <p>
              Po adresa mund të ndryshohet, por koha e transportit do të rritet
              për të paktën 24 orë.
            </p>
            <h3>Nëse dua, si mund ta anuloj porosinë?</h3>
            <p>
              Duke na kontaktuar në cilindo kanal të komunikimit (Facebook,
              Telefon apo Live Chat)
            </p>
            <h3>
              Kam anuluar porosinë, si mund ti shfrytëzoj mjetet financiare?
            </h3>
            <p>
              Duke bërë porosi të re për çfarëdo produkti që dëshironi brenda
              platformës butonks.com dhe duke shkruar në koment të porosisë që
              po shfrytëzoni mjetet ekzistuese në Point Computers.
            </p>
            <h3>
              Kam anuluar porosinë, kur më kthehen paratë (nëse pagesa është
              bërë online)?
            </h3>
            <p>
              Kompletimi i transakcionit bëhet brenda 5 ditëve, pasi të jetë
              bërë kërkesa për kthim të mjeteve nga ana jonë.
            </p>
            <h3>A mund ta dërgoj porosinë si dhuratë?</h3>
            <p>
              Po, mund të bëni pagesë online dhe tek adresa e transportit tek
              opsioni “<b>Shenimet e porosise</b>” të vendosni adresën ku duhet
              tē dërgohet porosia.
            </p>
            <h3>
              Produkti që dua të blej është në zbritje, a mund të më aplikohet
              edhe një tjetër?
            </h3>
            <p>
              Jo, për produktet që veçse janë në zbritje nuk mund të aplikohet
              zbritje shtesë.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
