import { 
  GET_SEARCH,
  SET_SEARCH,
  SET_SEARCH_CATEGORIES,
  GET_SEARCH_FILTERS,
  SET_SEARCH_FILTERS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  products: [],
  params: null,
  data: null,
  isLoading: false,
  isSearchLoading: false,
  categories: [],
  filters: null,
  filtersLoading: false,
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH:
      return {
        ...state,
        isLoading: action.parent ? true : false,
        data: action.data,
        categories: action.parent ? [] : state.categories,
        isSearchLoading: true
      };
    case SET_SEARCH:
      return {
        ...state,
        products: action.products,
        params: action.params,
        isSearchLoading: false,
        isLoading: false
      };
    case SET_SEARCH_CATEGORIES:
      return {
        ...state,
        categories: [action.category, ...state.categories]
      }
    case GET_SEARCH_FILTERS:
      return {
        ...state,
        filtersLoading: true
      }
    case SET_SEARCH_FILTERS:
      return {
        ...state,
        filtersLoading: false,
        filters: action.filters
      }
    default:
      return state
  }
};

export default searchReducer;