import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from "./routes/index";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom"; 
import { useSelector } from 'react-redux';
import Chatbot from 'react-chatbot-kit';
import config from '../src/bot/config';
import MessageParser from "../src/bot/MessageParser"
import ActionProvider from '../src/bot/ActionProvider';


function App() {
  const location = useLocation();
  const { alert } = useSelector((state) => state.custom);
  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);

  useEffect(() => {
    if(alert?.msg) toast(alert?.msg, { type: alert.type });
  }, [alert])

  return (
    <>
         <Header />
        <div className="main-container">
          <AllRoutes />
          <ToastContainer 
            position="bottom-right"
            autoClose={4000}
            pauseOnHover
            draggable
            closeOnClick
            limit={3}
          />
        </div>
        {/* <Chatbot config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider} /> */}

      <Footer />
    </>
  );
}

export default App;