import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

export default function Retruns() {
  return (
    <>
      <Breadcrumbs active_page="Kthimet" crumbs={[]} />
      <div className="container">
        <h3 className="page-title">Kthimet</h3>
        <div className="terms-container privacy-container">
          <div>
            <h3>Nulla molestie sit</h3>
            <p>
              Phasellus finibus enim nulla, quis ornare odio facilisis eu.
              Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor
              egestas. Aenean luctus, lorem in hendrerit interdum, leo orci
              egestas diam, ac euismod massa est et turpis. Etiam auctor lectus
              vel neque convallis pharetra. Ut turpis eros, aliquet non ante id,
              interdum placerat erat. Curabitur sit amet eros vel orci venenatis
              hendrerit. Cras sagittis sagittis sagittis. In hac habitasse
              platea dictumst. Phasellus diam erat, porttitor sed ligula at,
              ultricies auctor tellus. Donec ut sem in turpis ultrices suscipit
              ut auctor tellus. Quisque a tincidunt ipsum. Phasellus finibus
              enim nulla, quis ornare odio facilisis eu. Suspendisse ornare ante
              sit amet arcu semper, vel eleifend tortor egestas. Aenean luctus,
              lorem in hendrerit interdum, leo orci egestas diam, ac euismod
              massa est et turpis. Etiam auctor lectus vel neque convallis
              pharetra. Ut turpis eros, aliquet non ante id, interdum placerat
              erat. Curabitur sit amet eros vel orci venenatis hendrerit. Cras
              sagittis sagittis sagittis. In hac habitasse platea dictumst.
              Phasellus diam erat, porttitor sed ligula at, ultricies auctor
              tellus. Donec ut sem in turpis ultrices suscipit ut auctor tellus.
              Quisque a tincidunt ipsum.
            </p>
            <p>
              Phasellus finibus enim nulla, quis ornare odio facilisis eu.
              Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor
              egestas. Aenean luctus, lorem in hendrerit interdum, leo orci
              egestas diam, ac euismod massa est et turpis. Etiam auctor lectus
              vel neque convallis pharetra. Ut turpis eros, aliquet non ante id,
              interdum placerat erat. Curabitur sit amet eros vel orci venenatis
              hendrerit. Cras sagittis sagittis sagittis.
            </p>
          </div>
          <div>
            <h3>Nulla molestie sit</h3>
            <p>
              Phasellus finibus enim nulla, quis ornare odio facilisis eu.
              Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor
              egestas. Aenean luctus, lorem in hendrerit interdum, leo orci
              egestas diam, ac euismod massa est et turpis. Etiam auctor lectus
              vel neque convallis pharetra. Ut turpis eros, aliquet non ante id,
              interdum placerat erat. Curabitur sit amet eros vel orci venenatis
              hendrerit. Cras sagittis sagittis sagittis. In hac habitasse
              platea dictumst. Phasellus diam erat, porttitor sed ligula at,
              ultricies auctor tellus. Donec ut sem in turpis ultrices suscipit
              ut auctor tellus. Quisque a tincidunt ipsum.Phasellus finibus enim
              nulla, quis ornare odio facilisis eu. Suspendisse ornare ante sit
              amet arcu semper, vel eleifend tortor egestas. Aenean luctus,
              lorem in hendrerit interdum, leo orci egestas diam, ac euismod
              massa est et turpis. Etiam auctor lectus vel neque convallis
              pharetra. Ut turpis eros, aliquet non ante id, interdum placerat
              erat. Curabitur sit amet eros vel orci venenatis hendrerit. Cras
              sagittis sagittis sagittis. In hac habitasse platea dictumst.
              Phasellus diam erat, porttitor sed ligula at, ultricies auctor
              tellus. Donec ut sem in turpis ultrices suscipit ut auctor tellus.
              Quisque a tincidunt ipsum.
            </p>
          </div>
          <div>
            <h3>Nulla molestie sit</h3>
            <p>
              Phasellus finibus enim nulla, quis ornare odio facilisis eu.
              Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor
              egestas. Aenean luctus, lorem in hendrerit interdum, leo orci
              egestas diam, ac euismod massa est et turpis. Etiam auctor lectus
              vel neque convallis pharetra. Ut turpis eros, aliquet non ante id,
              interdum placerat erat. Curabitur sit amet eros vel orci venenatis
              hendrerit. Cras sagittis sagittis sagittis. In hac habitasse
              platea dictumst. Phasellus diam erat, porttitor sed ligula at,
              ultricies auctor tellus. Donec ut sem in turpis ultrices suscipit
              ut auctor tellus. Quisque a tincidunt ipsum.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
