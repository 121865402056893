import { put, call, select, takeEvery } from 'redux-saga/effects';
import { updateCompare, setCompareLength, setCompareAdded } from './actions'
import { setMessage } from '../custom/actions'
import { 
  GET_COMPARE,
  ADD_PRODUCT_COMPARE,
  REMOVE_PRODUCT_COMPARE,
} from './types'
import api from '../../../utils/axios'

// watcher
export function* compareWatcher() {
  yield takeEvery(GET_COMPARE, INIT);
  yield takeEvery(ADD_PRODUCT_COMPARE, ADDPRODUCT);
  yield takeEvery(REMOVE_PRODUCT_COMPARE, REMOVEPRODUCT);
}

// worker
export function* INIT() {
  try {
    let compare = JSON.parse(yield sessionStorage.getItem("compare"));
    if(compare) { 
      yield put(setCompareLength(compare.length));
      for(let item of compare){
        yield call(ADDTOCOMPARE, item);
      }
    }
  } catch (e){
    console.log(e);
  }
}

export const exists = (state, productId) => {
  return state.compare.compareProducts.some(obj => obj.product_id === productId);
}

function* ADDTOCOMPARE(productId) {
  let project = yield select((state) => exists(state, productId));
  if(project) return;
  
  const product = (yield api.get(`/products/${productId}`)).data;
  yield put(updateCompare(product))
}

function* ADDPRODUCT({ productId }) {
  try {
    let compare = JSON.parse(yield sessionStorage.getItem("compare"));
    if(!compare) compare = [];

    const index = compare.findIndex(item => item === productId);
    if(index !== -1) {
      yield put(setMessage('warning', 'Produkti tashme ekziston ne listen e krahasimeve.'));
      yield put(setCompareAdded());
    } else {
      compare.push(productId);
      yield put(setCompareLength(compare.length));
      yield call(ADDTOCOMPARE, productId);
      yield put(setMessage('success', 'Produkti u shtua ne listen e krahasimeve.'));
      yield sessionStorage.setItem("compare", JSON.stringify(compare))
    }
  } catch (e){
    console.log(e);
  }
}

function* REMOVEPRODUCT({ productId }) {
  try {
    let compare = JSON.parse(yield sessionStorage.getItem("compare"));
    if(compare){
      const index = compare.findIndex(item => item === productId);
      if(index !== -1) {
        compare.splice(index, 1);
        yield put(setMessage('success', 'Produkti u fshi nga lista e krahasimeve.'));
        yield sessionStorage.setItem("compare", JSON.stringify(compare))
      }
    } 
  } catch (e){
    console.log(e);
  }
}