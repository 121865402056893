import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

export default function Career() {
  return (
    <>
      <Breadcrumbs active_page="Karriera" crumbs={[]} />
      <div className="container">
        <h3 className="page-title">Karriera</h3>
        <div className="terms-container privacy-container">
          <div>
            <h3>Puna juaj n’ Point!</h3>
            <p>Jeni duke kërkuar për një punë që është më shumë se një punë?</p>
            <p>
              Mund të punosh kudo – por këtu nuk është kudo. Kjo sepse Buton
              është ndryshe nga kompanitë e tjera. Për ne, aspekti njerëzor
              është jashtëzakonisht i rëndësishëm. Dhe kjo është e qartë nga
              mënyra se si ne i trajtojmë klientët dhe partnerët tanë. Ne
              ushqejmë një marrëdhënie miqësore dhe të këndshme në një pozitë të
              barabartë. Bashkësia është një përbërës kyç i kulturës së
              kompanisë sonë. Prandaj, ne promovojmë krijimtarinë dhe zhvillimin
              e punonjësve tanë, pavarësisht nga gjinia, feja e tyre.
            </p>
            <p>
              Kjo është reflektuar edhe në formimin profesional dhe të avancuar
              të punonjësve tanë. Në fund të fundit, të mësuarit dhe
              vetëkontrolli i vazhdueshëm janë gjithashtu një pjesë e
              rëndësishme e identitetit tonë. Vetëm nëse shikojmë nga e ardhmja
              dhe mendojmë në terma të gjeneratave, mund të sigurojmë zhvillimin
              e qëndrueshëm të kompanisë sonë dhe ekipit tonë shumë të motivuar.
              Në fund të fundit, kjo përbën vazhdimësinë dhe cilësinë e punës
              sonë.
            </p>
            <p>
              Dëshironi të bashkoheni me ekipin tonë? Tingëllon interesante?{" "}
              <br />
              Hidhini një sy se cilat pozicione të hapura mund të jenë të
              përshtatshme për ju. Nuk gjetët gjë?
            </p>
            <p>
              Ndoshta je ti ai/ajo që po kërkojmë dhe thjesht nuk e dimë akoma.{" "}
              <br />
              Pra, na dërgoni një aplikim të pakërkuar - ndoshta ne mund ta
              zgjidhim atë në fund të fundit. Ekziston një gamë e gjerë
              disiplinash dhe ofertash pune në Buton – jemi të sigurt që ka
              diçka këtu edhe për ju. Tek ne mund të gjeni një punësim afatgjatë
              me perspektivë të mirë për të ardhmen tuaj në Kosovë dhe më gjerë.{" "}
              <br />
              Ne po kërkojmë vazhdimisht kolegë të rinj për fushat tona.
            </p>
            <p>
              Mjafton të na shkruani një e-mail në{" "}
              <a href="mailto:jobs@butonks.com" style={{ color: "#26A9E0" }}>
                jobs@butonks.com
              </a>{" "}
              . <br />
              Do të jemi të lumtur t&#39;ju ftojmë për një vizitë interviste në
              mënyrë që të mund të të njihemi me aftësiat tuaja.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
