import { 
  GET_DEALS,
  SET_DEALS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  isLoading: false,
  deals: null
};

export const dealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALS:
      return {
        ...state,
        isLoading: true
      };
    case SET_DEALS:
      return {
        ...state,
        isLoading: false,
        deals: action.deals
      };
    default:
      return state
  }
};

export default dealsReducer;