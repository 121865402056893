/********************
 ACTIONS
 ********************/
 import { 
  GET_SEARCH_PRODUCTS,
  SET_SEARCH_PRODUCTS
} from './types'

export const getSearchProducts = (keyword) => ({
  type: GET_SEARCH_PRODUCTS,
  keyword
});

export const setSearchProducts = (products) => ({
  type: SET_SEARCH_PRODUCTS,
  products
});
