import { 
  UPDATE_CART,
  REMOVE_PRODUCT,
  CART_LENGTH,
  CART_INC,
  CART_DEC,
  ADD_PRODUCT,
  RESET_ADDED,
  CLEAR_CART,
  CLEAR_CART_DB,
  SET_COUPON,
  CLEAR_COUPON,
  CART_TOTAL,
  GET_COUPON
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  cartProducts: [],
  subtotal: 0,
  coupon: null,
  isCouponLoading: false,
  couponDiscount: 0,
  total: 0,
  length: 0,
  isLoading: true,
  added: null
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        cartProducts: Array.isArray(action.product) ? action.product : [...state.cartProducts, action.product],
        added: null
      };
    case ADD_PRODUCT:
      return {
        ...state,
        added: action.productId
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        cartProducts: state.cartProducts.filter(item => (action.productId !== item.id && action.productId !== item.product_id)),
        length: state.length - 1
      };
    case CART_LENGTH:
      return {
        ...state,
        length: action.length, 
        isLoading: false
      };
    case RESET_ADDED:
      return {
        ...state,
        added: null
      };
    case CART_INC:
      return {
        ...state,
        added: null,
        cartProducts: state.cartProducts.map(item => {
          if(item.product_id === action.productId){
            item.quantity += action.quantity
          }
          return item;
        }), 
      };
    case CART_DEC:
      return {
        ...state,
        cartProducts: state.cartProducts.map(item => {
          if(item.product_id === action.productId){
            item.quantity -= action.quantity
          }
          return item;
        }),
      };

    case CLEAR_CART:
      return {
        ...state,
        cartProducts: [],
        length: 0,
        total:0
      };

    case CLEAR_CART_DB:
      return {
        ...state,
        cartProducts: [],
        length: 0
      };

    case GET_COUPON:
      return {
        ...state,
        isCouponLoading: true,
      };
    case CLEAR_COUPON:
      return {
        ...state,
        coupon: null,
        couponDiscount: 0,
        isCouponLoading: false
      };
    case SET_COUPON:
      return {
        ...state,
        coupon: action.coupon,
        isCouponLoading: false
      };
    case CART_TOTAL:
      const pt2 =  state?.coupon?.bonuses['2']?.discount_value;
      const subtotal = state.cartProducts.reduce((total, item) => (item.quantity * item.price + total), 0)
      const couponDiscount = pt2 ? ((pt2 / 100) * subtotal).toFixed(2) : 0;
      const total = couponDiscount <= 0 ? subtotal : subtotal - couponDiscount
      return {
        ...state,
        subtotal: subtotal,
        couponDiscount: couponDiscount,
        total: total
      }
    default:
      return state
  }
};

export default cartReducer;