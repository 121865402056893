/********************
 ACTIONS
 ********************/
import { GET_TRENDSHOME_PRODUCTS, SET_TRENDSHOME_PRODUCTS } from "./types";

export const getProducts = (data, parent = false) => ({
  type: GET_TRENDSHOME_PRODUCTS,
  data,
  parent,
});

export const setProducts = (products, parent) => ({
  type: SET_TRENDSHOME_PRODUCTS,
  products,
  parent,
});
