import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "../redux/app/profile/actions";

export default function Profile() {
  const { pathname } = useLocation();

  const activePage = () => {
    if (pathname === "/profile/changepassword") return "Ndrysho fjalekalimin";
    else return "Profili";
  };

  return (
    <>
      <Breadcrumbs
        active_page={activePage()}
        crumbs={[{ link: "/profile", title: "Te dhenat e profilit" }]}
      />

      <div className="container cart-container profile-container">
        <h3 className="page-title">Te dhenat e profilit</h3>
        <div
          className={`cart-details ${
            pathname === "/profile/changepassword" && "password-container"
          }`}
        >
          <div className="buy-form">
            <div className="tabs">
              <NavLink end to="/profile">
                <span>Profili</span>
              </NavLink>
              <NavLink to="/profile/changepassword">
                <span>Ndrysho fjalekalimin</span>
              </NavLink>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
