/********************
 ACTIONS
 ********************/
 import { 
  GET_COMPARE,
  UPDATE_COMPARE,
  ADD_PRODUCT_COMPARE,
  REMOVE_PRODUCT_COMPARE,
  SET_COMPARE_LENGTH,
  SET_COMPARE_ADDED
} from './types'

export const getCompare = () => ({
  type: GET_COMPARE
});

export const updateCompare = (product) => ({
  type: UPDATE_COMPARE,
  product
});

export const addProductToCompare = (productId) => ({
  type: ADD_PRODUCT_COMPARE,
  productId
});

export const removeProductFromCompare = (productId) => ({
  type: REMOVE_PRODUCT_COMPARE,
  productId
});

export const setCompareLength = (length) => ({
  type: SET_COMPARE_LENGTH,
  length
});

export const setCompareAdded = () => ({
  type: SET_COMPARE_ADDED
});