import { 
  UPDATE_COMPARE,
  REMOVE_PRODUCT_COMPARE,
  ADD_PRODUCT_COMPARE,
  SET_COMPARE_LENGTH,
  SET_COMPARE_ADDED
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  compareProducts: [],
  length: 0,
  added: null
};

export const compareReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPARE_LENGTH:
      return {
        ...state,
        length: action.length
      };
    case SET_COMPARE_ADDED:
      return {
        ...state,
        added: null
      };
    case ADD_PRODUCT_COMPARE:
      return {
        ...state,
        added: action.productId
      }
    case UPDATE_COMPARE:
      return {
        ...state,
        compareProducts: [...state.compareProducts, action.product],
        added: null
      };
    case REMOVE_PRODUCT_COMPARE:
      return {
        ...state,
        compareProducts: state.compareProducts.filter(item => action.productId !== item.product_id),
        length: state.length - 1
      };
    default:
      return state
  }
};

export default compareReducer;