import { all } from "redux-saga/effects";
import { authenticateWatcher } from "../app/auth/sagas";
import { ctWatcher } from "../app/categories/sagas";
import { featuredWatcher } from "../app/products/featured/sagas";
import { productsWatcher } from "../app/products/all/sagas";
import { productWatcher } from "../app/products/single/sagas";
import { trendsWatcher } from "../app/products/trends/sagas";
import { cartWatcher } from "../app/cart/sagas";
import { wishlistWatcher } from "../app/wishlist/sagas";
import { compareWatcher } from "../app/compare/sagas";
import { registerWatcher } from "../app/register/sagas";
import { searchInputWatcher } from "../app/search-input/sagas";
import { searchWatcher } from "../app/search/sagas";
import { profileWatcher } from "../app/profile/sagas";
import { ordersWatcher } from "../app/orders/sagas";
import { bannersWatcher } from "../app/banners/sagas";
import { dealsWatcher } from "../app/deals/sagas";
import { resetWatcher } from "../app/reset/sagas";
import { shippingsInputWatcher } from "../app/shippings/sagas";
import { trendsHomeWatcher } from "../app/products/trends-home/sagas";

export function* rootSaga() {
  yield all([
    authenticateWatcher(),
    ctWatcher(),
    cartWatcher(),
    wishlistWatcher(),
    featuredWatcher(),
    trendsWatcher(),
    trendsHomeWatcher(),
    productsWatcher(),
    productWatcher(),
    compareWatcher(),
    registerWatcher(),
    searchInputWatcher(),
    searchWatcher(),
    profileWatcher(),
    ordersWatcher(),
    bannersWatcher(),
    dealsWatcher(),
    resetWatcher(),
    shippingsInputWatcher(),
  ]);
}

export default rootSaga;
