import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { Rating } from "react-simple-star-rating";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Quantity } from "../components/Quantity";
import { ErrorMessage } from "../components/Custom";
import Button from "../components/Button";
import Carousel from "../components/SingleProduct/Carousel";
import Features from "../components/SingleProduct/Features";
import Products from "../components/Home/Products";
// import InnerHTML from 'dangerously-set-html-content'
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getShippings } from "../redux/app/shippings/actions";
import {
  addReview,
  getReviews,
  getSingleProduct,
  getSingleProductInfo,
} from "../redux/app/products/single/actions";
import { fixPrice } from "../utils/number";
import { addProductToWishlist } from "../redux/app/wishlist/actions";
import { addProductToCart } from "../redux/app/cart/actions";
import { addProductToCompare } from "../redux/app/compare/actions";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import ProductPdf from "../components/SingleProduct/ProductPdf";
import { savePDF } from "@progress/kendo-react-pdf";
import PreferedModelsHome from "../components/Home/PreferedModelsHome";
import PreferedModels from "../components/Home/PreferedModels";
import { setMessage } from "../redux/app/custom/actions";
import { getProducts } from "../redux/app/products/all/actions";
import ProductsSingle from "../components/Home/ProductsSingle";
import CarouselModal from "../components/SingleProduct/CarouselModal";

export default function SingleProduct() {
  const [rating, setRating] = useState(0);
  const [clicked, setClicked] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { products, page } = useSelector((state) => state.products);
  const [openModal, setOpenModal] = useState(false);
  const { cart, wishlist } = useSelector((state) => state);
  const { shippings } = useSelector((state) => state.shippings);
  useEffect(() => {
    dispatch(getShippings());
  }, [dispatch]);
  const {
    product,
    features,
    similarProducts,
    crumbs,
    isLoading,
    reviews,
    isSimilarProductsLoading,
    reviewLoading,
  } = useSelector((state) => state.product);

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isLargerDevice = useMediaQuery({ query: `(max-width: 1240px)` });
  // const inWishlist = (productId) =>
  //   wishlist.wishlistProducts &&
  //   wishlist.wishlistProducts.find((item) => item.product_id === productId);

  const inWishlist = (productId) => {
    for (let i = 0; i < wishlist.wishlistProducts.length; i++) {
      if (wishlist.wishlistProducts[i].product_id == productId) {
        return true;
      }
    }
    return false;
  };

  const CartSchema = Yup.object().shape({
    quantity: Yup.number().min(1).max(5).required().positive().integer(),
  });

  useEffect(() => {
    dispatch(getSingleProduct(params.productId));
    dispatch(getSingleProductInfo(params.productId));
    dispatch(getReviews(params.productId));
    dispatch(getShippings());
  }, [params.productId, dispatch]);

  useEffect(() => {
    if (cart.added !== product?.product_id && clicked) navigate("/cart/buy");
  }, [cart.added, clicked]);

  useEffect(() => {
    if (rating !== 0) handleReview(rating);
  }, [rating]);

  const buyNow = (values) => {
    setClicked(true);
    dispatch(
      addProductToCart(
        product.product_id,
        values.quantity,
        true,
        product.amount
      )
    );
  };

  const handleReview = (rate) => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/product/${params.productId}` } });
      return;
    }
    dispatch(addReview(params.productId, rate / 20));
  };

  const onlyProductOnCart = () => {
    if (cart.length === 1)
      return cart.cartProducts.some(
        (item) => item.product_id === params.productId
      );
    return false;
  };

  const componentRef = useRef();
  const handleDownload = () => {
    savePDF(componentRef.current, {
      title: `Product ${product.product_id}`,
      fileName: `Product ${product.product_id}`,
      paperSize: "A4",
    });
  };
  // const html = `${product?.full_description}`;
  const getMoreProducts = () => {
    dispatch(getProducts({ page: page + 1 }));
  };

  useEffect(() => {
    if (products.length <= 0) dispatch(getProducts({ page }));
  }, [dispatch]);

  const [transportDescription, setTransportDescription] = useState(false);

  const modal = (
    <>
      <>
        <div className="darkBG" onClick={() => setOpenModal(false)} />
        <div className="svgWrapper" onClick={() => setOpenModal(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
          </svg>
        </div>

        <div className="centered">
          <div className="modal">
            {/* <div className="modalHeader">
              <h5 className="heading">Dialog</h5>
            </div> */}
            {/* <button className="closeBtn" onClick={() => setOpenModal(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button> */}
            <div className="modalContent">
              {product && (
                <CarouselModal
                  product={product}
                  onClick={() => setOpenModal(true)}
                  images={{
                    [product.main_pair.pair_id]: product?.main_pair,
                    ...product?.image_pairs,
                  }}
                  discount={product?.list_discount_prc}
                  sold={product.amount < 1}
                  isMobile={isMobile}
                />
              )}
            </div>

          </div>
        </div>
      </>
    </>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumbs
            search={true}
            active_page={product.product}
            crumbs={crumbs}
          />
          <Helmet>
            <meta property="og:title" content={"Point Computers"} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
            <meta property="og:description" content={product.product} />
            <meta
              property="og:image"
              content={product.main_pair.detailed.image_path}
            />
          </Helmet>

          <div className="container">
            <div className="single-product">
              {openModal ? (
                <>
                  {modal}
                  <Carousel
                    product={product}
                    onClick={() => setOpenModal(true)}
                    images={{
                      [product.main_pair.pair_id]: product?.main_pair,
                      ...product?.image_pairs,
                    }}
                    discount={product?.list_discount_prc}
                    sold={product.amount < 1}
                    isMobile={isMobile}
                  />
                </>
              ) : (
                <Carousel
                  product={product}
                  onClick={() => setOpenModal(true)}
                  images={{
                    [product.main_pair.pair_id]: product?.main_pair,
                    ...product?.image_pairs,
                  }}
                  discount={product?.list_discount_prc}
                  sold={product.amount < 1}
                  isMobile={isMobile}
                />
              )}

              <div className="product-cart">
                <div className="product-info">
                  <div className="heading">
                    <h3 className="title">{product.product}</h3>
                    {isLargerDevice && (
                      <div className="mobile-price">
                        <div className="d-flex align-center">
                          {product.list_discount_prc > 0 && (
                            <h3 className="last-price">
                              {fixPrice(product.list_price)} &euro;
                            </h3>
                          )}
                          <h1 className="price">
                            {product.price !== "0.000000"
                              ? fixPrice(product.price) + " \u20AC"
                              : "Contact us for price"}
                          </h1>

                          {product.promotions && (
                            <h3 className="last-price" style={{marginLeft:"10px"}}>
                              {fixPrice(product.list_price)} &euro;
                            </h3>
                          )}
                        </div>
                        {product.list_discount_prc > 0 && (
                          <p className="spare">
                            Ju Kurseni:{" "}
                            <span>
                              {fixPrice(product.list_price - product.price)}{" "}
                              EURO
                            </span>
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="reviews">
                    <div className="d-flex">
                      <p className="all-reviews valuate">Vlereso produktin: </p>
                      <Rating
                        className={`stars ${reviewLoading && "loader"}`}
                        onClick={(rate) => setRating(rate)}
                        ratingValue={rating}
                        emptyIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.904"
                            height="19.881"
                            viewBox="0 0 25.264 24.029"
                          >
                            <g id="stars" transform="translate(2.18 2.293)">
                              <path
                                id="Path"
                                d="M10.452,0l3.195,6.592,7.257,1L15.622,12.67l1.289,7.211-6.459-3.455-6.46,3.455,1.29-7.211L0,7.594l7.257-1Z"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#f8bf4c"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        }
                        fullIcon={
                          <svg
                            id="stars"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.904"
                            height="19.881"
                            viewBox="0 0 20.904 19.881"
                          >
                            <path
                              id="Path"
                              d="M10.452,0l3.195,6.592,7.257,1L15.622,12.67l1.289,7.211-6.459-3.455-6.46,3.455,1.29-7.211L0,7.594l7.257-1Z"
                              transform="translate(0 0)"
                              fill="#f8bf4c"
                            />
                          </svg>
                        }
                      />
                    </div>

                    <div className="d-flex">
                      <svg
                        id="stars"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.904"
                        height="19.881"
                        viewBox="0 0 20.904 19.881"
                      >
                        <path
                          id="Path"
                          d="M10.452,0l3.195,6.592,7.257,1L15.622,12.67l1.289,7.211-6.459-3.455-6.46,3.455,1.29-7.211L0,7.594l7.257-1Z"
                          transform="translate(0 0)"
                          fill="#f8bf4c"
                        />
                      </svg>
                      <p className="all-reviews">
                        ({("0" + reviews).slice(-2)})
                      </p>
                    </div>
                  </div>

                  <div className="kodi-krahaso">
                    <div className="d-flex justify-between align-center compare-reviews">
                      <div>
                        <p>
                          <span>Kodi i Produktit</span>: {product.product_code}
                        </p>

                        {product.out_of_stock_actions === "B" ? (
                          <div
                            onMouseOver={() => setTransportDescription(true)}
                            onMouseOut={() => setTransportDescription(false)}
                            style={{ cursor: "pointer" }}
                          >
                            <p>
                              <span>Koha e arritjes së produktit</span>:{" "}
                              {shippings && shippings[1]?.delivery_time}
                              {console.log(
                                "shippings-1",
                                shippings?.delivery_time
                              )}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p>
                              <span>Koha e arritjes së produktit</span>:{" "}
                              {shippings && shippings[0]?.delivery_time}
                              {console.log(
                                "shippings-1",
                                shippings && shippings[0]
                              )}
                            </p>
                          </div>
                        )}

                        {transportDescription && (
                          <div
                            className=""
                            style={{
                              border: "1px solid red",
                              padding: "5px",
                              backgroundColor: "white",
                              margin: "10px 0",
                              borderRadius: "5px",
                              boxShadow: "5px 5px 5px red",
                              position: "absolute",
                            }}
                          >
                            <p>
                              Koha e arritjes së produktit nënkupton periudhën
                              kur produkti mund të arrij tek ju .
                            </p>
                            <p>
                              * Vikendet dhe festat kombëtare mund të ndikojnë
                              që produkti juaj të ketë vonesa
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-center mobile-all-reviews">
                        <svg
                          id="stars"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.904"
                          height="19.881"
                          viewBox="0 0 20.904 19.881"
                        >
                          <path
                            id="Path"
                            d="M10.452,0l3.195,6.592,7.257,1L15.622,12.67l1.289,7.211-6.459-3.455-6.46,3.455,1.29-7.211L0,7.594l7.257-1Z"
                            transform="translate(0 0)"
                            fill="#f8bf4c"
                          />
                        </svg>
                        <p className="all-reviews">(00)</p>
                      </div>
                    </div>
                    <div className="singleProduct-pdf">
                      <Link
                        to="/compare"
                        onClick={() =>
                          dispatch(addProductToCompare(product.product_id))
                        }
                        className="d-flex align-center to-compare"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.534"
                          height="15.618"
                          viewBox="0 0 16.534 15.618"
                        >
                          <g
                            id="Group_3"
                            data-name="Group 3"
                            transform="translate(-1960.276 -121.174)"
                          >
                            <path
                              id="fi-rr-arrow-down"
                              d="M13.693,11.578a.651.651,0,0,0-.924,0L10.406,13.94V.651A.651.651,0,0,0,9.756,0h0A.651.651,0,0,0,9.1.651V13.934L6.749,11.578a.651.651,0,1,0-.9.918L8.4,15.047a1.952,1.952,0,0,0,2.759,0L13.713,12.5A.651.651,0,0,0,13.693,11.578Z"
                              transform="translate(1954.703 121.175)"
                              fill="#888"
                            />
                            <path
                              id="fi-rr-arrow-up"
                              d="M5.9,4.042a.651.651,0,0,0,.918,0L9.152,1.68V14.97a.651.651,0,0,0,.651.651h0a.651.651,0,0,0,.651-.651V1.687L12.81,4.042a.651.651,0,0,0,.924-.918L11.19.574a1.952,1.952,0,0,0-2.759,0L5.9,3.125A.651.651,0,0,0,5.9,4.042Z"
                              transform="translate(1962.886 121.172)"
                              fill="#888"
                            />
                          </g>
                        </svg>
                        <span>Krahaso</span>
                      </Link>
                      <Button
                        title="Shkarko PDF-n"
                        classes="d-flex align-center to-compare"
                        onClick={handleDownload}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.533"
                            height="19.289"
                            viewBox="0 0 16.533 19.289"
                          >
                            <path
                              id="Icon_metro-file-pdf"
                              data-name="Icon metro-file-pdf"
                              d="M18.372,6.294a2.5,2.5,0,0,1,.517.818,2.484,2.484,0,0,1,.215.947v12.4a1.029,1.029,0,0,1-1.033,1.033H3.6a1.029,1.029,0,0,1-1.033-1.033V3.237A1.029,1.029,0,0,1,3.6,2.2h9.644a2.485,2.485,0,0,1,.947.215,2.5,2.5,0,0,1,.818.517ZM13.593,3.667V7.715H17.64a1.176,1.176,0,0,0-.237-.441L14.034,3.9a1.176,1.176,0,0,0-.441-.237Zm4.133,16.447V9.092H13.249a1.029,1.029,0,0,1-1.033-1.033V3.581H3.948V20.115H17.726Zm-5.533-6.383a8.19,8.19,0,0,0,.9.6,10.681,10.681,0,0,1,1.259-.075q1.582,0,1.905.527a.52.52,0,0,1,.022.56.031.031,0,0,1-.011.022l-.022.022V15.4q-.065.409-.764.409a4.44,4.44,0,0,1-1.238-.215,7.849,7.849,0,0,1-1.4-.57,19.382,19.382,0,0,0-4.219.893q-1.647,2.82-2.6,2.82a.626.626,0,0,1-.3-.075l-.258-.129q-.011-.011-.065-.054a.445.445,0,0,1-.065-.387,2.338,2.338,0,0,1,.6-.985,5.2,5.2,0,0,1,1.421-1.039.157.157,0,0,1,.248.065.062.062,0,0,1,.022.043q.56-.915,1.152-2.12A16.429,16.429,0,0,0,9.9,11.234a8.7,8.7,0,0,1-.328-1.717,4.185,4.185,0,0,1,.07-1.372q.118-.431.452-.431h.237a.454.454,0,0,1,.377.161.86.86,0,0,1,.1.732.233.233,0,0,1-.043.086.28.28,0,0,1,.011.086V9.1a14.1,14.1,0,0,1-.151,2.067,5.446,5.446,0,0,0,1.572,2.562Zm-6.2,4.424a4.753,4.753,0,0,0,1.475-1.7,6.136,6.136,0,0,0-.942.9A3.578,3.578,0,0,0,5.994,18.156Zm4.284-9.9a3.2,3.2,0,0,0-.022,1.421q.011-.075.075-.474,0-.032.075-.463a.242.242,0,0,1,.043-.086.031.031,0,0,1-.011-.022.022.022,0,0,0-.005-.016.022.022,0,0,1-.005-.016.62.62,0,0,0-.14-.387.031.031,0,0,1-.011.022v.022ZM8.943,15.368A15.788,15.788,0,0,1,12,14.5a1.624,1.624,0,0,1-.14-.1,1.928,1.928,0,0,1-.172-.145,5.7,5.7,0,0,1-1.367-1.894,14.384,14.384,0,0,1-.893,2.12q-.323.6-.484.893ZM15.9,15.2a2.576,2.576,0,0,0-1.507-.258,4.071,4.071,0,0,0,1.335.3,1.044,1.044,0,0,0,.194-.011q0-.011-.022-.032Z"
                              transform="translate(-2.571 -2.203)"
                              fill="#888"
                            />
                          </svg>
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="cart-form">
                  <div className="cart">
                    <div className="prices">
                      <h1 className="price">
                        {product.price !== "0.000000"
                          ? fixPrice(product.price) + " \u20AC"
                          : "Contact us for price"}
                      </h1>
                      {product.promotions && (
                        <h3 className="last-price">
                          {fixPrice(product.list_price)} &euro;
                        </h3>
                      )}
                      {product.list_discount_prc > 0 && (
                        <h3 className="last-price">
                          {fixPrice(product.list_price)} &euro;
                        </h3>
                      )}
                      <p className="tvsh-included">* Përfshirë TVSH-në</p>
                      {product.list_discount_prc > 0 && (
                        <p className="spare">
                          Ju Kurseni:{" "}
                          <span style={{ color: "red" }}>
                            {fixPrice(product.list_price - product.price)}{" "}
                            &euro;
                          </span>
                        </p>
                      )}
                    </div>
                    {product.amount > 0 && (
                      <Formik
                        initialValues={{ quantity: 1 }}
                        validationSchema={CartSchema}
                        onSubmit={buyNow}
                      >
                        {({ values, errors, touched, setFieldValue }) => (
                          <Form>
                            <div className="form">
                              <div
                                className="d-flex justify-center align-center "
                                style={{ gap: "10px" }}
                              >
                                <p>Sasia</p>
                                <Quantity
                                  val={values.quantity}
                                  setFieldValue={setFieldValue}
                                  min={1}
                                  max={product.amount}
                                />
                              </div>
                              {errors.quantity && touched.quantity && (
                                <ErrorMessage message={errors.quantity} />
                              )}

                              {/* {product.amount > 0 && product.price !== "0.000000" && (cart.length < 1 || onlyProductOnCart()) &&
                            <Button
                              type='submit'
                              classes='green block buy-now'
                              title='Blej menjehere'
                              loading={cart.added === product.product_id && clicked}
                            />
                          } */}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>

                  {product.amount == 0 &&
                  product.out_of_stock_actions === "S" ? (
                    <>
                      <div className="product-notification">
                        <input
                          type="email"
                          name="email"
                          className="inputSignUpForNotification"
                          placeholder="Shkruaj emailin per tu njoftuar"
                        />
                        <Button
                          type="submit"
                          classes="green block buy-now"
                          title="Më njofto kur ky produkt është në dispozicion"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <Formik
                    initialValues={{ quantity: 1 }}
                    validationSchema={CartSchema}
                    onSubmit={buyNow}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <div
                          className="d-flex align-center others"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            alignContent: "center",
                            gap: "10px",
                          }}
                        >
                          {product.amount == 0 &&
                            product.out_of_stock_actions == "B" &&
                            product.price !== "0.000000" && (
                              <Button
                                classes={`${
                                  cart.length < 1 || onlyProductOnCart()
                                    ? "outline blue"
                                    : "green border-radius-4"
                                }`}
                                title="Shto ne shportë"
                                onClick={() => {
                                  setClicked(false);
                                  dispatch(
                                    addProductToCart(
                                      product.product_id,
                                      values.quantity,
                                      true,
                                      product.amount
                                    )
                                  );
                                }}
                                loading={
                                  cart.added === product.product_id && !clicked
                                }
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                  >
                                    <path
                                      id="fi-rr-shopping-bag"
                                      d="M14.875,4.25H12.75a4.25,4.25,0,1,0-8.5,0H2.125A2.125,2.125,0,0,0,0,6.375v7.083A3.546,3.546,0,0,0,3.542,17h9.917A3.546,3.546,0,0,0,17,13.458V6.375A2.125,2.125,0,0,0,14.875,4.25ZM8.5,1.417A2.833,2.833,0,0,1,11.333,4.25H5.667A2.833,2.833,0,0,1,8.5,1.417Zm7.083,12.042a2.125,2.125,0,0,1-2.125,2.125H3.542a2.125,2.125,0,0,1-2.125-2.125V6.375a.708.708,0,0,1,.708-.708H4.25V7.083a.708.708,0,0,0,1.417,0V5.667h5.667V7.083a.708.708,0,0,0,1.417,0V5.667h2.125a.708.708,0,0,1,.708.708Z"
                                      transform="translate(0 0)"
                                      fill={`${
                                        cart.length < 1 || onlyProductOnCart()
                                          ? "#FFF"
                                          : "#FFF"
                                      }`}
                                    />
                                  </svg>
                                }
                              />
                            )}
                          {product.amount > 0 &&
                            product.out_of_stock_actions == "S" && (
                              <Button
                                classes={`${
                                  cart.length < 1 || onlyProductOnCart()
                                    ? "outline blue"
                                    : "green border-radius-4"
                                }`}
                                title="Shto ne shporte"
                                onClick={() => {
                                  setClicked(false);
                                  dispatch(
                                    addProductToCart(
                                      product.product_id,
                                      values.quantity,
                                      true,
                                      product.amount
                                    )
                                  );
                                }}
                                loading={
                                  cart.added === product.product_id && !clicked
                                }
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                  >
                                    <path
                                      id="fi-rr-shopping-bag"
                                      d="M14.875,4.25H12.75a4.25,4.25,0,1,0-8.5,0H2.125A2.125,2.125,0,0,0,0,6.375v7.083A3.546,3.546,0,0,0,3.542,17h9.917A3.546,3.546,0,0,0,17,13.458V6.375A2.125,2.125,0,0,0,14.875,4.25ZM8.5,1.417A2.833,2.833,0,0,1,11.333,4.25H5.667A2.833,2.833,0,0,1,8.5,1.417Zm7.083,12.042a2.125,2.125,0,0,1-2.125,2.125H3.542a2.125,2.125,0,0,1-2.125-2.125V6.375a.708.708,0,0,1,.708-.708H4.25V7.083a.708.708,0,0,0,1.417,0V5.667h5.667V7.083a.708.708,0,0,0,1.417,0V5.667h2.125a.708.708,0,0,1,.708.708Z"
                                      transform="translate(0 0)"
                                      fill={`${
                                        cart.length < 1 || onlyProductOnCart()
                                          ? "#FFF"
                                          : "#FFF"
                                      }`}
                                    />
                                  </svg>
                                }
                              />
                            )}

                          <Button
                            classes="outline greenn"
                            loading={wishlist.added === product.product_id}
                            onClick={() => {
                              if (inWishlist(product.product_id)) {
                                return;
                              } else {
                                dispatch(
                                  addProductToWishlist(product.product_id)
                                );
                              }
                            }}
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.486"
                                height="18"
                                viewBox="0 0 20.486 18"
                              >
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect
                                      id="Rectangle_1565"
                                      data-name="Rectangle 1565"
                                      width="17.357"
                                      height="14.826"
                                      fill="#EA1B25"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="Group_369"
                                  data-name="Group 369"
                                  transform="translate(-396.001 -2194)"
                                >
                                  <g
                                    id="Group_366"
                                    data-name="Group 366"
                                    transform="translate(397.566 2195.586)"
                                  >
                                    <g
                                      id="Group_365"
                                      data-name="Group 365"
                                      transform="translate(0 0)"
                                      clipPath="url(#clip-path)"
                                    >
                                      <path
                                        id="Path_737"
                                        data-name="Path 737"
                                        d="M10.34,14.221a2.578,2.578,0,0,1-3.321,0C2.563,10.483.006,6.9.006,4.379A4.167,4.167,0,0,1,3.909,0a4.472,4.472,0,0,1,3.9,3.157.867.867,0,1,0,1.734,0A4.528,4.528,0,0,1,13.448,0a4.167,4.167,0,0,1,3.9,4.379c0,2.517-2.556,6.1-7.012,9.839Z"
                                        transform="translate(0 0)"
                                        fill={
                                          inWishlist(product.product_id)
                                            ? "#EA1B25"
                                            : "transparent"
                                        }
                                      />
                                    </g>
                                  </g>
                                  <path
                                    id="fi-rr-heart"
                                    d="M14.931,1.917a5.461,5.461,0,0,0-4.693,2.816A5.461,5.461,0,0,0,5.545,1.917,5.8,5.8,0,0,0,0,7.932c0,3.88,4.084,8.117,7.509,10.99a4.244,4.244,0,0,0,5.461,0c3.425-2.873,7.509-7.11,7.509-10.99a5.8,5.8,0,0,0-5.546-6.015Zm-3.059,15.7a2.537,2.537,0,0,1-3.268,0c-4.384-3.678-6.9-7.207-6.9-9.684a4.1,4.1,0,0,1,3.84-4.309A4.4,4.4,0,0,1,9.385,6.73a.853.853,0,0,0,1.706,0,4.456,4.456,0,0,1,3.84-3.106,4.1,4.1,0,0,1,3.84,4.309c0,2.477-2.515,6.006-6.9,9.681Z"
                                    transform="translate(396.006 2192.083)"
                                    fill="#EA1B25"
                                  />
                                </g>
                              </svg>
                            }
                          />
                        </div>
                        <div
                          style={{
                            padding: "20px 0",
                          }}
                        >
                          {" "}
                          <img
                            alt="bank cards"
                            src="/assets/images/icons/image26.png"
                          ></img>
                          <p>
                            Paguaj me TEB Starcard deri në 12 këste pa kamatë!
                          </p>
                        </div>
  
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>

            <Features />

            <Products
              // products={ similarProducts }
              // isLoading={ isSimilarProductsLoading }
              headingTitle="Shiko produktet tjera"
              showMore={false}
            />
            <ProductsSingle products={products} isLoading={isLoading}>
              <div className="d-flex justify-center">
                <Button
                  title="Shiko me shume"
                  classes="outline blue see-more"
                  onClick={getMoreProducts}
                />
              </div>
            </ProductsSingle>
            {/* <PreferedModels rows={2} heading="Shiko produktet tjera" /> */}

            <div style={{ height: 0, overflow: "hidden" }}>
              {product && (
                <ProductPdf
                  reff={componentRef}
                  product={product}
                  features={features}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
