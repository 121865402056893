import { 
  GET_SEARCH_PRODUCTS,
  SET_SEARCH_PRODUCTS
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  keyword: null,
  products: [],
  isLoading: false
};

export const searchInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_PRODUCTS:
      return {
        ...state,
        keyword: action.keyword,
        isLoading: true
      };
    case SET_SEARCH_PRODUCTS:
      return {
        ...state,
        products: action.products,
        isLoading: false
      };
    default:
      return state
  }
};

export default searchInputReducer;