import { put, call, takeLatest } from 'redux-saga/effects';
import { 
  setSimilarProducts, 
  setSingleProduct, 
  setSingleProductInfo, 
  getSimilarProducts as similarProducts,
  setProductCrumbs,
  setReviews,
  addReviewResponse
} from './actions'
import { setMessage } from '../../custom/actions'
import api, { user_api } from '../../../../utils/axios'

import { 
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_INFO,
  GET_SIMILAR_PRODUCTS,
  GET_REVIEWS,
  ADD_REVIEW
} from './types'

// watcher
export function* productWatcher() {
  yield takeLatest(GET_SINGLE_PRODUCT, getSingleProduct);
  yield takeLatest(GET_SINGLE_PRODUCT_INFO, getSingleProductInfo);
  yield takeLatest(GET_SIMILAR_PRODUCTS, getSimilarProducts);

  yield takeLatest(GET_REVIEWS, GETREVIEWS);
  yield takeLatest(ADD_REVIEW, ADDREVIEW);
}

function* getCategory(ctgId){
  const category = (yield api.get(`/categories/${ctgId}`)).data
  yield put(setProductCrumbs({
    id: category.category_id,
    title: category.category,
    link: category.seo_name
  }))
  if(category.parent_id !== "0") yield call(getCategory, category.parent_id); 
}

// worker
export function* getSingleProduct({ productId }) {
  try {
    const data = (yield api.get(`/products/${productId}`)).data
    yield put(setSingleProduct(data));
    yield call(getCategory, data.category_ids[data.category_ids.length - 1]);
    yield put(similarProducts(data));
  } catch (e){
    console.log(e);
  }
}

export function* getSingleProductInfo({ productId }) {
  try {
    const data = (yield api.get(`/products/${productId}/features?items_per_page=`)).data
    const unique = [...new Set(data.features.map(item => item.parent_id))];
    
    let newArray = [];
    for(let i of unique){
      const feature = (yield api.get(`/features/${i}`)).data
      const features = data.features.filter(item => item.parent_id === i)
      feature.subs = features;
      newArray.push(feature);
    }
    yield put(setSingleProductInfo(newArray));
  } catch (e){
    console.log(e);
  }
}

export function* getSimilarProducts({ product }) {
  try {
    const data = (yield api.get(`/categories/${product.category_ids[product.category_ids.length - 1]}/products`)).data
    yield put(setSimilarProducts(data.products));
  } catch (e){
    console.log(e);
  }
}

export function* GETREVIEWS({ productId }) {
  try {
    const data = (yield api.get(`/product_reviews`, {
      params: { product_id: productId }
    })).data
    yield put(setReviews(Object.keys(data.product_reviews[0]).length))
  } catch (e){
    console.log(e);
  }
}

export function* ADDREVIEW({ productId, rating }) {
  try {
    const reviewAdded = yield sessionStorage.getItem("reviewAdded");
    if(reviewAdded === productId) { // just a small validation to stop spamming
      yield put(setMessage("info", "You already posted a review for this product."));
      yield put(addReviewResponse(false));
      return;
    }

    yield user_api.post(`/product_reviews`, {
      product_id: productId,
      rating_value: rating,
      comment: 'Dump text.'
    })
    yield put(setMessage("success", "Review posted successfully."));
    yield put(addReviewResponse(true));

    yield sessionStorage.setItem("reviewAdded", productId);
  } catch (e){
    console.log(e);
    yield put(setMessage("warning", "Kishte nje problem, provoni perseri!"));
    yield put(addReviewResponse(false));
  }
}