import { 
  CREATE_ORDER,
  CREATE_ORDER_RESPONSE,
  GET_ORDERS,
  RESET_ORDER,
  SET_ORDERS,
  SET_NOTES
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  orders: [],
  isLoading: false,
  isBeingCreated: false,
  created: false,
  order_id: null,
  notes: ''
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_ORDERS:
      return {
        ...state,
        isLoading: false,
        orders: action.orders,
      };
    case CREATE_ORDER:
      return {
        ...state,
        isBeingCreated: true
      };
    case CREATE_ORDER_RESPONSE:
      return {
        ...state,
        isBeingCreated: false,
        created: action.status,
        order_id: action.order_id,
        notes: action.status ? '' : state.notes
      };
    case RESET_ORDER:
      return {
        ...state,
        isBeingCreated: false,
        created: false,
        order_id: null,
        notes: ''
      };
    case SET_NOTES:
      return {
        ...state,
        notes: action.notes
      };
    default:
      return state
  }
};

export default ordersReducer;