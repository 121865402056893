/********************
 ACTIONS
 ********************/
import { 
  CREATE_ORDER,
  CREATE_ORDER_RESPONSE,
  GET_ORDERS,
  RESET_ORDER,
  SET_NOTES,
  SET_ORDERS
} from './types'

export const getOrders = () => ({
  type: GET_ORDERS
});

export const setOrders = (orders) => ({
  type: SET_ORDERS,
  orders
});

export const createOrder = (order) => ({
  type: CREATE_ORDER,
  order
});

export const createOrderResponse = (status, order_id) => ({
  type: CREATE_ORDER_RESPONSE,
  status,
  order_id
})

export const resetOrder = () => ({
  type: RESET_ORDER
})

export const setNotes = (notes) => ({
  type: SET_NOTES,
  notes
})