/********************
 ACTIONS
 ********************/
 import { 
  GET_SINGLE_PRODUCT, 
  SET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_INFO,
  SET_SINGLE_PRODUCT_INFO,
  GET_SIMILAR_PRODUCTS,
  SET_SIMILAR_PRODUCTS,
  SET_PRODUCT_CRUMBS,
  GET_REVIEWS,
  SET_REVIEWS,
  ADD_REVIEW,
  ADD_REVIEW_RESPONSE
} from './types'

export const getSingleProduct = (productId) => ({
  type: GET_SINGLE_PRODUCT,
  productId
});

export const setSingleProduct = (product) => ({
  type: SET_SINGLE_PRODUCT,
  product,
});

export const getSingleProductInfo = (productId) => ({
  type: GET_SINGLE_PRODUCT_INFO,
  productId
});

export const setSingleProductInfo = (features) => ({
  type: SET_SINGLE_PRODUCT_INFO,
  features,
});

export const getSimilarProducts = (product) => ({
  type: GET_SIMILAR_PRODUCTS,
  product
});

export const setSimilarProducts = (products) => ({
  type: SET_SIMILAR_PRODUCTS,
  products,
});

export const setProductCrumbs = (category) => ({
  type: SET_PRODUCT_CRUMBS,
  category,
});

export const getReviews = (productId) => ({
  type: GET_REVIEWS,
  productId,
});

export const setReviews = (count) => ({
  type: SET_REVIEWS,
  count,
});

export const addReview = (productId, rating) => ({
  type: ADD_REVIEW,
  productId,
  rating
});

export const addReviewResponse = (status) => ({
  type: ADD_REVIEW_RESPONSE,
  status,
});