import { put, takeLatest } from "redux-saga/effects";
import { setCategories } from "./actions";
import api from "../../../utils/axios";

import { GET_CATEGORIES } from "./types";

// watcher
export function* ctWatcher() {
  yield takeLatest(GET_CATEGORIES, INIT);
}

// worker
export function* INIT({ parent }) {
  try {
    const data = (yield api.get("/categories", {
      params: {
        status: "A",
        items_per_page: 500,
        get_images: true,
        group_by_level: true,
        parent,
      },
    })).data.categories;

    const categories = new Map();
    const allCategories = {};
    for (let item of data) {
      categories.set(item.category_id, { ...item, subs: [] });
      allCategories[item.category_id] = { ...item, subs: [] };
    }

    for (const [key, value] of categories.entries()) {
      if (value.parent_id !== "0") {
        const category = categories.get(value.parent_id);
        category["subs"].push(value);
        categories.set(value.parent_id, category);
      }
    }

    for (const [key, value] of categories.entries()) {
      if (value.parent_id !== "0") categories.delete(key);
    }

    yield put(setCategories(categories, allCategories));
  } catch (e) {
    console.log(e);
  }
}
