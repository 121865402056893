import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

export default function WarrantyServis() {
  return (
    <>
      <Breadcrumbs active_page="Garancioni dhe servisi" crumbs={[]} />
      <div className="container">
        <h3 className="page-title">Garancioni dhe servisi</h3>
        <div className="terms-container privacy-container">
          <div>
            <h3>KUSHTET E GARANCIONIT</h3>
            <p>
              Periudha e garancionit fillon nga data e blerjes dhe perfundon ne
              afatin e caktuar për vlefshmërin e garancionit në bazë të
              produktit. Sherbimi i garancionit do të kryhet në vendet e
              përcaktuara për servisim nga Point Computers kurse Shërbimet e
              transportit për produktin I cili është mbrenda garancionit i bartë
              vetë blerësi. Garancioni mbulon riparimin (kthimin ne gjendje
              pune) falas të produkteve të shitura mbrenda afatit të garancionit
              për produktin e blerë nga Point Computers.
            </p>
            <p>
              Që të jetë I vlefshëm garancioni për produktin e blerë, pala
              blerëse duhet ti përmbahet këtyre udhëzimeve:
            </p>
            <ul>
              <li>
                {" "}
                - Produkti të reklamohet prane servisit brenda periudhes së
                garancionit dhe të sillet në servis mbrenda këtij afati.
              </li>
              <li>
                {" "}
                - Produkti shoqërohet me Faturen e Blerjes, si dhe duhet të ketë
                numrin serik të pa dëmtuar.
              </li>
              <li>
                {" "}
                - Produkti duhet të mos jetë I hapur apo servisuar paraprakisht
                nga personat e pa autorizuar nga Point Computers
              </li>
            </ul>
            <p style={{ marginLeft: 20 }}>
              {" "}
              - Garancioni i produktit nuk vlen nëse kushtet e lartëshënuara nuk
              jan të plotësuara nga blerësi
            </p>
            <p>
              Për të gjitha produktet e blera nga <b>Point Computers</b> afati
              për reklamimin e produktit në rast se produkti ka probleme është
              24 orë.
            </p>
            <p>
              Blerësi është I obliguar që produktin ta sjell me të gjitha
              paisjet përcjellëse që I posedon produkti, si dhe paketimin
              original të tij, ndërsa Point Computers me pranimin e reklamimit
              të produktit është I obliguar që ta zëvendësoj atë me produkt të
              ri apo kthimin e mjeteve nëse nuk ka produkt të njejtë për
              zëvendësim apo sipas marrëveshjes edhe me produktin që pala
              dëshiron dhe dallimi i cmimit paguhet, respektivisht kthehet.
            </p>
            <p>
              Point Computers është i obliguar që të bëjë diagnostifikimin e
              paisjes në afat prej 72 orëve nga koha e pranimit në servis.{" "}
              <br />
              Point Computers ka për obiligim eleminimin e defektit sa më shpejt
              që është e mundur, në rastin me të keq për një periudhë 15-40 ditë
              pune kur produkti duhet te kthehet tek prodhuesi, ne te kunderten
              afati I servisimit eshte 8-14 Dite.
            </p>
            <p>
              Në asnjë rast pjesë e garancionit nuk janë çantat, kabllot,
              filterët e ndryshëm, telekomandat, adapterët, bateritë dhe pjesët
              e ngjashme, si dhe produktet e konsumueshme.
            </p>
            <p>
              Në rast se defekti nuk mund te eliminohet, Point Computers merr
              përsipër zëvendësimin me një produkt te njejtë apo rimbursimin e
              produktit. Gjithashtu produkti mund te zëvendesohet me një produkt
              me karakteristika te njejta ose më të mira, PC Comp sh.p.k nuk
              merrë përgjegjësi për zavendësimin e njejtë të produktit i cili
              është jashtë prodhimit dhe tregut. Koha e garancionit të produktit
              të zëvendësuar/riparuar zgjatet për 15 ditë të tjera nëse defekti
              ka ndodhur në 30 ditëshin e fundit te garancionit, përndryshe
              ruhet data e skadencës së produktit fillestar.
            </p>
            <p>
              Paisjet e brendit <b>SENCOR, Lamart</b>
            </p>
            <p>
              Të gjitha produktet elektroshtëpiake të vogla kan garancion 2 VITE
              (Mikrovalë, Shtrydhëse për fruta- perime, Mikser, Tost, Ngroës
              uji, Hekur rrobash, Fshesa, etj.) të markës SENCOR të blera në
              Kosovë.
            </p>
            <p>
              Për të gjitha produktet SENCOR të shitura në Kosovë, në periudhën
              e garancionit por edhe pas saj, pikat tona të servisit sigurojnë
              pjesë këmbimi origjinale nga fabrika. Për shërbimin e garancionit,
              ju lutemi kontaktoni shërbimin e shënuar më poshtë. SENCOR
              garanton që të gjitha pajisjet dhe aksesorët e prodhuara prej saj,
              janë konform specifikave teknike të deklaruara dhe janë të
              kualifikuara për përdorimet e parashikuara. <br />
              Përdorimi komfort këtyre specifikimeve e udhëzimeve përkatëse të
              përdorimit, bën të mundur edhe përfitimin e garancionit sipas
              produkteve përkatëse. Ky garancion përfitohet për pajisje që
              përdoren në ambiente familjare banimi.{" "}
              <b>
                Nëse pajisjet janë përdorur në biznes, ata nuk mund të mbulohen
                nga ky garancion.
              </b>
            </p>
            <p>Garancioni nuk vlenë për:</p>
            <ul>
              <li>
                {" "}
                - Produktet me demtime fizike nga keqperdorimi ose pakujdesia.
              </li>
              <li>
                {" "}
                - Pjeset e konsumueshme te produkteve Printer, Fotokopje, etj
                (toner, zhvillues, cilinder, etj).
              </li>
              <li> - Programet (demtimi/fshirja/humbja e tyre).</li>
              <li> - Demtimi nga viruset.</li>
              <li>
                Demtimi i plote/pjesshem i programeve apo informacioneve per
                shkak te defekteve te produktit.
              </li>
            </ul>
            <p></p>
            <p>
              Eshte pergjegjesia e klientit ruajtja dhe rikuperimi i
              informacioneve ne produktet qe përdorë.
            </p>
            <ul>
              <li>Garancioni nuk vlenë per defektet e shkaktuara nga:</li>
              <li>
                {" "}
                - Mosrespektimi i instruksioneve per instalimin/perdorimin e
                produktit.
              </li>
              <li>
                {" "}
                - Perdorimi i produktit ne ambiente te papershtateshme (pluhur,
                lageshtire, dridhje, nxehtesi, etj).
              </li>
              <li>
                {" "}
                - Materiale te lengshme (uje, etj,),zjarri, shkaqe natyrore apo
                vjedhje.
              </li>
              <li>
                {" "}
                - Mbitensioni elektrik ose puna me tension jashte kufijve 230
                +/- 15% dhe te percaktuara ne manualin e produktit.
              </li>
              <li>
                {" "}
                - Nese produktit i jane kryer sherbime apo instalime nga persona
                te pa autorizuar nga Point Computers
              </li>
              <li>
                {" "}
                - Produktet “Monitor” nese kane me pak se 9 pika defektoze, jo
                te perqendruara (distance ≥ 15mm).
              </li>
              <li>
                {" "}
                - Llampat e projektoreve pas afatit kohore 30 ditë nga blerja.
              </li>
              <li> - Bateria e Laptopit pas afatit kohor prej 6 Muaj.</li>
              <li>
                {" "}
                - Defektet e shkaktuara mbi produktin per shkak te lidhjes se
                tij me produkte te tjera, jo te blera ose te instaluara nga
                Point Computers.
              </li>
              <li>
                - Dëmet që mund të shkaktohen klinetit apo palëve të treta në
                aktivitetin e tyre, nga mosfunksionimi i produktit. Kjo është e
                vlefshme edhe kur ky mosfunksionim është për shkak të defekteve
                që mbulohen nga garancia.
              </li>
              <li>
                {" "}
                - Dëmet që mund të shkaktohen nga softverët e pa licencuara
              </li>
            </ul>
            <p></p>
            <p>
              Vërjejtje: POINT COMPUTERS nuk merrë përgjegjësi për instalimin e
              softverëve për të cilët pala nuk posedon licencë.
            </p>
            <p>
              Me rastin e shitjes së softverit të licencuar nga POINT COMPUTERS
              ajo dëshmohet në faturën blerëse.
            </p>
          </div>
          <div>
            <h3>SERVISIMI / RIPARIMI</h3>
            <p>
              Nëse keni problem me produktet e blera në Point Computers, mos
              hezitoni që sa më parë të kontaktoni Servisin e Autorizuar.
              Specialistët tanë jan në dispozicionin tuaj që problemin tuaj ta
              sanojn sa më parë që të jetë e mundur!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
