import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


export default function CarouselModal({
    images,
    discount,
    sold,
    isMobile,
    onClick,
    product,
    innerProduct
}) {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const slickSettingsVerticalNav = {
        arrows: false,
        slidesToShow: 3,
        swipeToSlide: true,
        focusOnSelect: true,
        lazyLoad: true,
        asNavFor: nav2,
        infinite: false,
    };

    const slickSettingsVerticalMain = {
        arrows: true,
        dots: isMobile ? true : false,
        slidesToShow: 1,
        swipe: true,
        lazyLoad: true,
        asNavFor: nav1,
        infinite: false,
    };

  


    return (
        
        <>
           <div className="modal-carousel-p w3-container w3-center w3-animate-zoom">
        <p>{product.product}</p>
        </div>
        <div className="carousel w3-container w3-center w3-animate-zoom" >
            {!images.undefined && (
                <>
                    <div className="active-image position-relative ">
                        <Slider
                            style={{ zIndex: '0' }}
                            fade={true}
                            {...slickSettingsVerticalMain}
                            ref={(slider2) => setNav2(slider2)}
                        >
                            {Object.keys(images).map((item , index) => {
                                if (
                                    product.amount == 0 &&
                                    product.out_of_stock_actions == "S"
                                ) {
                                    return (
                                        <div className="soldWrapper singleProductSoldWrapper">
                                            <img
                                                key={item}

                                                src={images[item]?.detailed?.image_path}
                                                alt="Featured 1"
                                                className="soldImage"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="position-relative imageWrapper">
                                            <img
                                                key={item}
                                                style={{ width: '400px', height: 'auto' }}
                                                onClick={onClick}
                                                src={images[item]?.detailed?.image_path}
                                                alt="Featured 1"
                                            />
                                            { innerProduct &&( index === Object.keys(images)?.length - 1) && <>
                                                <Link className={`buyNowButtonLink ${index === Object.keys(images)?.length - 1 ? 'active' : ''}`} to={`/product/${product?.product_id}`}><div className="buyNowButton" >Blej tani</div></Link>
                                            </>}
                                        </div>
                                    );
                                }
                            })}
                        </Slider>
                    </div>
                    {/* {!sold && discount > 0 && (
                        <span className="tag-discount">Kurseni {discount}%</span>
                    )} */}

                    {/* {!isMobile && (
                        <div className="thumbs" >
                            <Slider
                                style={{ zIndex: '0' }}
                                {...slickSettingsVerticalNav}
                                ref={(slider1) => setNav1(slider1)}
                            >
                                {Object.keys(images).map((item) => (
                                    <img
                                        key={item}
                                        src={images[item]?.detailed?.image_path}
                                        alt="Featured 1"
                                    />
                                ))}
                            </Slider>
                        </div>
                    )} */}
                </>
            )}
        </div>
        </>
    );
}