import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({
  images,
  discount,
  sold,
  isMobile,
  onClick,
  product,
}) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const slickSettingsVerticalNav = {
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: true,
    asNavFor: nav2,
    infinite: false,
  };

  const slickSettingsVerticalMain = {
    arrows: false,
    dots: isMobile ? true : false,
    slidesToShow: 1,
    swipe: true,
    lazyLoad: true,
    asNavFor: nav1,
    infinite: false,
  };


  return (
    <div className="carousel">
      {!images.undefined && (
        <>
          <div className="active-image position-relative">
            <Slider
              style={{ zIndex: '0' }}
              fade={true}
              {...slickSettingsVerticalMain}
              ref={(slider2) => setNav2(slider2)}
            >
              {Object.keys(images).map((item) => {
                if (
                  product.amount == 0 &&
                  product.out_of_stock_actions == "S"
                ) {
                  return (
                    <div className="soldWrapper singleProductSoldWrapper">
                      <p className="soldText">E shitur</p>
                      <img
                        key={item}

                        src={images[item]?.detailed?.image_path}
                        alt="Featured 1"
                        className="soldImage"
                      />
                    </div>
                  );
                } else {
                  return (
                    <img
                      key={item}
                      onClick={onClick}
                      src={images[item]?.detailed?.image_path}
                      alt="Featured 1"
                    />
                  );
                }
              })}
            </Slider>
          </div>
          {!sold && discount > 0 && (
            <span className="tag-discount">Kurseni {discount}%</span>
          )}

          {!isMobile && (
            <div className="thumbs" >
              <Slider
                style={{ zIndex: '0' }}
                {...slickSettingsVerticalNav}
                ref={(slider1) => setNav1(slider1)}
              >
                {Object.keys(images).map((item) => (
                  <img
                    key={item}
                    src={images[item]?.detailed?.image_path}
                    alt="Featured 1"
                  />
                ))}
              </Slider>
            </div>
          )}
        </>
      )}
    </div>
  );
}
