import { 
  SET_CATEGORIES
} from './types'

/********************
 REDUCER
 ********************/

export const initialState = {
  categories: null,
  allCategories: null
};

export const ctReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
        allCategories: action.allCategories
      };

    default:
      return state
  }
};

export default ctReducer;