import React, { useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { ErrorMessage } from "../components/Custom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { register, resetRegister } from "../redux/app/register/actions";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../components/PasswordInput";

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSubmitting, email } = useSelector((state) => state.register);

  const dispatchRegister = useCallback(
    (state) => dispatch(register(state)),
    [dispatch]
  );
  const dispatchResetRegister = useCallback(
    () => dispatch(resetRegister()),
    [dispatch]
  );

  // const RegisterValidation = Yup.object().shape({
  //   name: Yup.string().required("Name is required"),
  //   lastname: Yup.string().required("Lastname is required"),
  //   email: Yup.string().email().required("Email is required"),
  //   password: Yup.string()
  //     .required("Password is required")
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{8,})/,
  //       "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
  //     ),
  //   confPassword: Yup.string().oneOf(
  //     [Yup.ref("password"), null],
  //     "Passwords must match"
  //   ),
  // });
  const RegisterValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    lastname: Yup.string().required("Lastname is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password is required"),
      // .min(1, "Password must be at least 6 characters!"),
    confPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  useEffect(() => {
    if (email && !isSubmitting) navigate("/login", { state: { email } });
    return () => dispatchResetRegister();
  }, [email, isSubmitting, dispatch]);

  const handleSubmit = (values) => {
    dispatchRegister(values);
  };

  return (
    <>
      <Breadcrumbs active_page="Llogaria ime" />
      <div className="account-forms">
        <Formik
          initialValues={{
            name: "",
            lastname: "",
            email: "",
            password: "",
            confPassword: "",
          }}
          validationSchema={RegisterValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <h4 className="title">Krijo llogari</h4>

              <div className="form-group">
                <label htmlFor="name">Emri*</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Emri*"
                />
                {errors.name && touched.name && (
                  <ErrorMessage message={errors.name} />
                )}
              </div>

              <div className="form-group">
                <label htmlFor="lastname">Mbiemri*</label>
                <Field
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Mbiemri*"
                />
                {errors.lastname && touched.lastname && (
                  <ErrorMessage message={errors.lastname} />
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email*"
                />
                {errors.email && touched.email && (
                  <ErrorMessage message={errors.email} />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Fjalekalimi*</label>
                <PasswordInput
                  name="password"
                  errors={errors}
                  touched={touched}
                />
              </div>

              <div className="form-group">
                <label htmlFor="confPassword">Perserit fjalekalimin*</label>
                <PasswordInput
                  name="confPassword"
                  errors={errors}
                  touched={touched}
                />
              </div>

              <div className="d-flex flex-column align-center">
                <Button
                  type="submit"
                  title="Krijo llogarine"
                  classes="green submit"
                  loading={isSubmitting}
                />
                <Link to="/login">
                  Keni llogari? <span> Kyquni</span>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
