/********************
 ACTIONS
 ********************/
 import { 
  GET_CART,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_CART,
  CART_LENGTH,
  CART_INC,
  CART_DEC,
  RESET_ADDED,
  ADD_ALL_TO_WISHLIST,
  CLEAR_CART,
  CLEAR_CART_DB,
  ADD_TO_DB,
  CART_TOTAL,
  GET_COUPON,
  SET_COUPON,
  CLEAR_COUPON,
  COUPON_INIT
} from './types'

export const getCart = () => ({
  type: GET_CART
});

export const addProductToCart = (productId, quantity, force = false, max, showMsg = true) => {
  // console.log(productId, quantity, force, max, showMsg);
  return {
    type: ADD_PRODUCT,
    productId,
    quantity,
    force,
    max,
    showMsg
  }
}

export const addProductToCartNull = (productId, quantity, force = false, max, showMsg = true) => {
  // console.log(productId, quantity, force, max, showMsg);
  return {
    type: ADD_PRODUCT,
    productId,
    quantity,
    force,
    max,
    showMsg
  }
}

export const removeProductFromCart = (productId) => ({
  type: REMOVE_PRODUCT,
  productId
});

export const updateCart = (product) => ({
  type: UPDATE_CART,
  product
});

export const setCartLength = (length) => ({
  type: CART_LENGTH,
  length
})

export const productInc = (productId, quantity = 1, force = false) => ({
  type: CART_INC,
  productId,
  quantity,
  force
})

export const productDec = (productId, quantity = 1) => ({
  type: CART_DEC,
  productId,
  quantity
})

export const resetAdded = () => ({
  type: RESET_ADDED
})

export const addAllToWishlist = () => ({
  type: ADD_ALL_TO_WISHLIST
})

export const clearCart = () => ({
  type: CLEAR_CART
})

export const clearCartDB = (productId) => ({
  type: CLEAR_CART_DB
})

export const addToDb = () => ({
  type: ADD_TO_DB
})

export const calcTotal = () => ({
  type: CART_TOTAL
})

export const couponInit = () => ({
  type: COUPON_INIT
})

export const getCoupon = (coupon) => ({
  type: GET_COUPON,
  coupon
})

export const setCoupon = (coupon) => ({
  type: SET_COUPON,
  coupon
})

export const clearCoupon = () => ({
  type: CLEAR_COUPON
})