const axios = require('axios');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // https://pointcomputers.bbros.al/api
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(process.env.REACT_APP_API_KEY)
  }
});

export const user_api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // https://pointcomputers.bbros.al/api
  timeout: 80000,
  headers: {
    'Content-Type': 'application/json'
  }
});

user_api.interceptors.request.use((config) => {
  let token = localStorage.getItem('token');
  if(token) config.headers['Authorization'] = 'Basic ' + btoa(token);
  return config;
});

user_api.interceptors.response.use((response) => {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem('token');
    localStorage.removeItem('auth.user');
    window.location.href = '/';
  } else return Promise.reject(error);
});

export default api;